import { useContext, createContext, useState, useEffect } from 'react';

import toast from 'react-hot-toast';

const downloadsContext = createContext();

export function DownloadsProvider({ children })
{
    const downloads = useDownloadsProvider();

    return <downloadsContext.Provider value={downloads}>{children}</downloadsContext.Provider>
};

export const useDownloads = () => {
    return useContext(downloadsContext);
};

function useDownloadsProvider()
{
    const extractFilename = (path) => {
        const pathArray = path.split("/");
        const lastIndex = pathArray.length - 1;

        return pathArray[lastIndex];
    };

    const start = (url) => {
        toast((t) => (
            <div>
                <div className="font-bold">Download Started</div>
                <div className="text-sm">{extractFilename(url)}</div>

                <div className="mt-2">
                    <button type="button" className="hover:text-primary" onClick={() => toast.dismiss(t.id)}>Dismiss</button>
                </div>
            </div>
        ), {
            position : 'bottom-right'
        });

        if (window.ipcRenderer)
        {
            window.ipcRenderer.send('download-start', {
                url : url
            });
        }
    };

    useEffect(() => {
        if (window.ipcRenderer)
        {
            window.ipcRenderer.on('download-complete', (event, args) => {
                toast((t) => (
                    <div>
                        <div className="font-bold">
                            Download Complete
                        </div>
    
                        <div className="text-sm">
                            {extractFilename(args.savePath)}
                        </div>
    
                        <div className="mt-2">
                            <button className="hover:text-primary" onClick={() => { toast.dismiss(t.id); window.ipcRenderer.send('show-file', { path : args.savePath }); }}>Open containing folder</button>
                        </div>
                    </div>
                ), {
                    position: 'bottom-right'
                });
            });
        }
    }, []);

    return {
        start
    };
};