import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';


export default function DateRange({ value, setValue }) {
  const datepickerConfig = {
    shortcuts: {
      today: 'Today',
      yesterday: 'Yesterday',
      pastWeek: {
        text: 'Past Week',
        period: {
          start: moment().subtract(1, 'week'),
          end: moment()
        }
      },
      past2Weeks: {
        text: 'Past 2 Weeks',
        period: {
          start: moment().subtract(2, 'weeks'),
          end: moment()
        }
      },
      past4Weeks: {
        text: 'Past 4 Weeks',
        period: {
          start: moment().subtract(4, 'weeks'),
          end: moment()
        }
      },
      currentMonth: 'This Month',
      pastMonth: 'Last Month',
      yearToDate: {
        text: 'Year to Date',
        period: {
          start: moment().startOf('year'),
          end: moment()
        }
      },
      past2years: {
        text: 'Past 2 Years',
        period: {
          start: moment().subtract(2, 'years'),
          end: moment()
        }
      },
    }
  };

  const handleChange = (newValue) => {
    if (typeof(setValue) === 'function') setValue(newValue);
  };

  return (
    <Datepicker 
      value={value}
      onChange={handleChange}
      displayFormat="MMM D, YYYY"
      separator="to"
      inputClassName="w-64 py-1 rounded border border-gray-200 focus:ring-primary focus:border-primary"
      primaryColor="rose"
      showShortcuts={true}
      useRange={false}
      startWeekOn="mon"
      configs={datepickerConfig}
      /> 
  )
}