import buildVersion from './version';

let config = {
  version: `${buildVersion.buildMajor}.${buildVersion.buildMinor}.${buildVersion.buildRevision}`
};

console.log(process.env.REACT_APP_ENV);

if (process.env.REACT_APP_ENV === 'development') {
  //Development config
  config = {
    ...config, ...{
      apiUrl: 'http://localhost:1337/',
      backendUrl: 'http://localhost:1337/',
      socketUrl: 'ws://localhost:1337',
    }
  };
} else {
  //Production config
  config = {
    ...config, ...{
      apiUrl: 'https://apiv2.officegab.com/',
      backendUrl: 'https://apiv2.officegab.com/',
      socketUrl: 'https://apiv2.officegab.com',
    }
  };
}

export default config;