import { useState } from 'react';

import classNames from "classnames";

const Tooltip = (props) => {
    const [show, setShow] = useState(false);

    return (
        <div className={classNames('relative', props.className)} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <div className={classNames('absolute whitespace-nowrap bg-gray-900 text-white rounded px-2 py-0.5 text-sm -mt-8 object-top -left-7', { 'invisible' : !show, 'visible' : show }, props.className)}>{props.title}</div>

            {props.children}
        </div>
    );
};

export default Tooltip;