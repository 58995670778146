import { useState, useContext, useEffect, createContext } from 'react';

import { useApi } from './useApi';

import config from 'config';
import axios from 'axios';

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
};

export const useAuth = () => {
  return useContext(authContext);
};

function useAuthProvider() {
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const api = useApi();

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const token = JSON.parse(tokenString);

    return token;
  };

  const saveToken = (userToken) => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      axios.post(config.apiUrl + 'auth/login', {
        email: email,
        password: password
      }).then((response) => {
        saveToken(response.data.token);
        loadUser();
        resolve(response);
      }).catch((error) => {
        reject();
      });
    });
  };

  const loginAsUser = (userId) => {
    return new Promise((resolve, reject) => {
      api.get(`auth/loginAsUser/${userId}`).then((response) => {
        saveToken(response.data.token);
        loadUser();
        resolve(response);
      }).catch((error) => {
        reject();
      });
    });
  };

  const loadUser = () => {
    api.get('auth/user').then((response) => {
      setUser(response.data);
    }).catch((error) => {
      logout();
    });
  };

  const logout = () => {
    localStorage.setItem('token', null);
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const token = getToken();

    loadUser();
    
    setToken(token);
  }, []);

  return {
    token,
    user,
    login,
    loginAsUser,
    logout,
    loadUser
  };
};