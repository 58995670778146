import { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, Toaster, ToastBar } from 'react-hot-toast';

import { Switch, Route, Link, NavLink, Navigate, useLocation, Redirect, matchPath, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import moment from 'moment';

import Tooltip from 'components/tooltip';
import UserAvatar from 'components/user-avatar';
import Modal from 'components/modal';

import { useData } from 'hooks/useData';
import { useAuth } from 'hooks/useAuth';
import { useSocket } from 'hooks/useSocket';

import ViewHome from 'views/user/home';
import ViewChat from 'views/user/chat';
import ViewTasks from 'views/user/tasks';
import ViewProfile from 'views/user/profile';
import ViewStaff from 'views/user/staff';
import ViewSearch from 'views/user/search';

import Logo from 'img/logo.png';

import config from 'config';

const ViewMain = () => {
  const location = useLocation();
  const socket = useSocket();
  const data = useData();
  const auth = useAuth();
  const history = useHistory();

  const [currentDateTime, setCurrentDateTime] = useState(moment().format('dddd - MMMM Do, YYYY h:mm A'));

  const [loading, setLoading] = useState(true);


  const incompleteAssignedTaskCount = () => {
    const assignedTasks = data.tasks.filter((task) => {
      const taskUserIds = task.taskUsers.map((taskUser) => taskUser.userId);

      return (taskUserIds.indexOf(auth.user.id) >= 0 && !task.completed);
    });

    return assignedTasks.length;
  };


  const unreadTaskCount = () => {
    return data.userUnreadTasks.length;
  };


  const unreadMessageCount = () => {
    let unreadMessageCount = 0;

    data.userUnreadMessages.forEach((userUnreadMessage) => {
      const userUnreadMessageModuleName = userUnreadMessage.moduleName.toLowerCase();

      if (userUnreadMessageModuleName === 'direct' || userUnreadMessageModuleName === 'channel') {
        unreadMessageCount += 1;
      }
    });

    return unreadMessageCount;
  };


  const unreadTaskMessageCount = () => {
    let unreadTaskMessageCount = 0;

    data.userUnreadMessages.forEach((userUnreadMessage) => {
      const userUnreadMessageModuleName = userUnreadMessage.moduleName.toLowerCase();

      if (userUnreadMessageModuleName === 'task') {
        unreadTaskMessageCount += 1;
      }
    });

    return unreadTaskMessageCount;
  };


  const pathMatches = (path) => {
    return matchPath(path, {
      path: location.pathname,
      exact: true,
      strict: false
    }) ? true : false;
  };


  const loadData = async () => {
    setLoading(true);

    await Promise.all([
      data.loadUsers(),
      data.loadUserUnreadTasks(),
      data.loadUserUnreadMessages(),
      data.loadTasks(),
      data.loadChannels(),
      data.loadTaskPriorities()
    ]).catch((error) => {
      if (error.status === 401) {
        auth.logout();
      }
    });

    setLoading(false);
  };


  useEffect(async () => {
    loadData();

    setInterval(() => {
      setCurrentDateTime(moment().format('dddd - MMMM Do, YYYY h:mm A'));
    }, 1000);

    if (location.pathname == '/' || location.pathname == '/login') {
      history.push('/home');
    }
  }, []);

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen flex flex-col bg-white dark:bg-gray-800">
        <div className="flex items-center bg-gray-50 dark:bg-gray-900 border-b border-b-gray-200 dark:border-b-0">
          <div className="px-4">
            <img src={Logo} style={{ maxWidth: '260px' }} />
          </div>

          <div className="grow text-gray-600">
            <NavLink to="/home" className={classNames('text-center py-4 px-7 inline-block relative hover:bg-gray-200')} activeClassName="bg-primary text-white hover:bg-primary group is-selected">
              <div>
                <FontAwesomeIcon icon={['fad', 'house-chimney']} size="2x" fixedWidth />
              </div>

              <div className="font-semibold">Home</div>
            </NavLink>

            <NavLink to="/chat" className={classNames('text-center py-4 px-7 inline-block relative hover:bg-gray-200')} activeClassName="bg-primary text-white hover:bg-primary group is-selected">
              <div>
                <FontAwesomeIcon icon={['fad', 'comment-alt-lines']} size="2x" fixedWidth />
              </div>

              <div className="font-semibold">Gab</div>

              {unreadMessageCount() > 0 && (
                <span className="absolute top-2 right-2 bg-primary text-white w-6 h-6 rounded-full text-center border-2 border-white text-sm font-bold shadow">{unreadMessageCount()}</span>
              )}
            </NavLink>

            <NavLink to="/tasks" className={classNames('text-center py-4 px-7 inline-block relative hover:bg-gray-200')} activeClassName="bg-primary text-white hover:bg-primary group is-selected">
              <div>
                <FontAwesomeIcon icon={['fad', 'tasks']} size="2x" fixedWidth />
              </div>

              <div className="font-semibold">Tasks</div>

              {unreadTaskCount() > 0 && (
                <span className="absolute top-2 right-2 bg-primary text-white w-6 h-6 rounded-full text-center border-2 border-white text-sm font-bold shadow">{unreadTaskCount()}</span>
              )}

              {unreadTaskMessageCount() > 0 && (
                <span className="absolute top-2 left-2 text-primary animate-bounce group-[.is-selected]:text-white">
                  <FontAwesomeIcon icon={['fas', 'message']} />
                </span>
              )}
            </NavLink>

            <NavLink to="/search" className={classNames('text-center py-4 px-7 inline-block relative hover:bg-gray-200')} activeClassName="bg-primary text-white hover:bg-primary group is-selected">
              <div>
                <FontAwesomeIcon icon={['fad', 'search']} size="2x" fixedWidth />
              </div>

              <div className="font-semibold">Search</div>
            </NavLink>
          </div>

          <div className="px-4">
            <Menu as="div" className="relative inline-block text-left z-10">
              <div>
                <Menu.Button className="items-center justify-center w-full p-2 text-gray-700 rounded focus:outline-none hover:bg-gray-100">
                  <div className="inline-block align-middle mr-2">
                    <UserAvatar user={auth.user} className="w-10 h-10 rounded border-2 border-white shadow" />
                  </div>

                  <div className="inline-block text-lg align-middle">
                    {auth.user?.firstName} {auth.user?.lastName}
                  </div>

                  <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" className="ml-2" />
                </Menu.Button>
              </div>

              <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right rounded absolute right-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      <Link to="/profile" className="block px-4 py-2 w-full text-left hover:bg-gray-50">
                        <FontAwesomeIcon icon={['far', 'id-card-clip']} className="mr-3 text-gray-400" fixedWidth />My Profile
                      </Link>
                    </Menu.Item>
                  </div>

                  {auth.user?.role === 'ADMIN' && (
                    <div className="py-1">
                      <Menu.Item>
                        <Link to="/staff" className="block px-4 py-2 w-full text-left hover:bg-gray-50">
                          <FontAwesomeIcon icon={['far', 'users']} className="mr-3 text-gray-400" fixedWidth />Manage Staff
                        </Link>
                      </Menu.Item>
                    </div>
                  )}

                  <div className="border-t border-gray-100"></div>

                  <div className="py-1">
                    <Menu.Item>
                      <button type="button" className="block px-4 py-2 w-full text-left hover:bg-gray-50" onClick={() => { auth.logout(); }}>
                        <FontAwesomeIcon icon={['far', 'arrow-right-from-bracket']} className="mr-3 text-gray-400" fixedWidth />Sign Out
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="flex-grow overflow-y-auto">
          <div className={classNames('h-full ', { 'hidden': !pathMatches('/home'), 'block': pathMatches('/home') })}>
            <ViewHome />
          </div>

          <div className={classNames('h-full', { 'hidden': !pathMatches('/chat'), 'block': pathMatches('/chat') })}>
            <ViewChat />
          </div>

          <div className={classNames('h-full', { 'hidden': !pathMatches('/tasks'), 'block': pathMatches('/tasks') })}>
            <ViewTasks />
          </div>

          <div className={classNames('h-full', { 'hidden': !pathMatches('/search'), 'block': pathMatches('/search') })}>
            <ViewSearch />
          </div>

          <div className={classNames('h-full', { 'hidden': !pathMatches('/profile'), 'block': pathMatches('/profile') })}>
            <ViewProfile />
          </div>

          <div className={classNames('h-full', { 'hidden': !pathMatches('/staff'), 'block': pathMatches('/staff') })}>
            <ViewStaff />
          </div>
        </div>

        <div className="border-t border-gray-200 p-2 flex items-center">
          <div className="text-gray-700">
            OfficeGab Desktop v{config.version}
          </div>

          <div className="flex-grow">

          </div>

          <div className="px-4">
            <FontAwesomeIcon icon={['far', 'clock']} className="mr-2 text-gray-400" />{currentDateTime}
          </div>

          <div className="pr-4">
            <Tooltip title="Refresh application data" className="right-0 left-auto">
              <button type="button" className="hover:text-primary" onClick={() => loadData()}>
                <FontAwesomeIcon icon={['fas', 'arrows-rotate']} />
              </button>
            </Tooltip>
          </div>

          <div>
            {socket.connected && (
              <Tooltip title="Connected to live services" className="right-0 left-auto">
                <FontAwesomeIcon icon={['far', 'cloud-check']} className="text-green-600 mr-2" />
              </Tooltip>
            )}

            {!socket.connected && (
              <Tooltip title="Disconnected from live services. Attempting to reconnect." className="right-0 left-auto">
                <FontAwesomeIcon icon={['far', 'cloud-slash']} className="text-red-600 mr-2" />
              </Tooltip>
            )}
          </div>
        </div>
      </div>


      <Modal show={loading} setShow={() => { }} className="w-64">
        <div className="p-10 text-center">
          <div>
            <FontAwesomeIcon icon={['fad', 'spinner']} className="text-primary" spin size="3x" />
          </div>

          <div className="mt-5 text-gray-600 font-medium">Loading...</div>
        </div>
      </Modal>
    </>
  );
};


export default ViewMain;