import { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';
import moment from 'moment';

import Modal from 'components/modal';
import UserSelect from 'components/user-select';
import PrioritySelect from 'components/priority-select';
import UserAvatar from 'components/user-avatar';
import Messages from 'components/messages';
import ComposeMessage from 'components/compose-message';
import DeleteTask from './delete-task';

import { useAuth } from 'hooks/useAuth';
import { useApi } from 'hooks/useApi';
import { useSocket } from 'hooks/useSocket';


const TaskDetails = ({ show, setShow, task }) => {
  const socket = useSocket();
  const auth = useAuth();
  const api = useApi();

  const [user, setUser] = useState(false);
  const [title, setTitle] = useState('');
  const [dueDate, setDueDate] = useState(moment().format('YYYY-MM-DD'));
  const [users, setUsers] = useState([]);
  const [priority, setPriority] = useState(5);
  const [completed, setCompleted] = useState(false);

  const [editTask, setEditTask] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);


  const updateTask = (event) => {
    event.preventDefault();

    return new Promise((resolve, reject) => {
      const request = {
        users: users
      };

      if (title != task.title) request.title = title;
      if (moment(dueDate).utc().toISOString() != task.dueDate) request.dueDate = moment(dueDate).utc().toISOString();
      if (priority != task.priorityValue) request.priorityValue = priority;
      if (completed != task.completed) request.completed = completed;

      api.put(`task/${task.id}`, request).then((response) => {
        const task = response.data;

        setEditTask(false);

        resolve(task);
      });
    });
  };

  const toggleCompleted = () => {
    api.put(`task/${task.id}`, { completed: !completed });
  };

  const taskDeleted = () => {
    setShow(false);
  };

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDueDate(moment.utc(task.dueDate).local().format('YYYY-MM-DD'));
      setUsers(task.taskUsers.map((taskUser) => taskUser.userId));
      setPriority(task.priority.value);
      setCompleted(task.completed);
      setUser(task.user);
    }
  }, [task]);

  useEffect(() => {
    if (!show) {
      setEditTask(false);
    }
  }, [show]);


  return (
    <>
      <Modal show={show ? true : false} setShow={setShow} className="max-w-3xl">
        {task && (
          <>
            <div className="pl-4 pr-4 pt-4">
              <div className="flex items-center">
                <div className="pr-4">
                  <FontAwesomeIcon className="text-primary inline-block" icon={['fal', 'circle-check']} size="2x" />
                </div>

                <div className="flex-grow">
                  <span className="text-xl">Task Details</span>
                </div>

                {task.user?.id == auth.user.id && (
                  <>
                    <div className="px-2">
                      <button type="button" className="px-3 py-1 bg-gray-100 rounded hover:bg-primary hover:text-white" onClick={() => setDeleteTask(task)}>
                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                      </button>
                    </div>

                    <div className="px-2">
                      {!editTask && (
                        <button type="button" className="px-3 py-1 bg-gray-100 rounded hover:bg-primary hover:text-white" onClick={() => setEditTask(true)}>
                          Edit Task
                        </button>
                      )}
                    </div>
                  </>
                )}

                <div>
                  <button type="button" onClick={() => setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                    <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                  </button>
                </div>
              </div>
            </div>

            <div className="p-8">
              <form className="space-y-4" onSubmit={updateTask}>
                <div>
                  <label className="text-gray-600">Title</label>

                  <div className="mt-2">
                    {!editTask && (
                      <div className={classNames('text-xl', { 'line-through' : completed })}>{task.title}</div>
                    )}

                    {editTask && (
                      <input type="text" id="text" className="bg-gray-50 block w-full border-0 rounded focus:ring-0 focus:bg-gray-100" placeholder="Enter a title for your new task..." value={title} onChange={(e) => setTitle(e.target.value)} required />
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-8">
                  <div>
                    <label className="text-gray-600">Completed</label>

                    <div className="mt-2">
                      <Fragment>
                        <button type="button" className={classNames('w-6 h-6 border-2 rounded', { 'bg-gray-50 border-gray-200' : !completed, 'bg-green-400 border-green-500': completed })} onClick={(e) => { toggleCompleted(); }}>
                          {completed && <FontAwesomeIcon icon={['fas', 'check']} className="mb-0.5 text-white" />}
                        </button>
                      </Fragment>
                    </div>
                  </div>

                  <div>
                    <label className="text-gray-600">Priority</label>

                    <div className="mt-2">
                      {!editTask && (
                        <div className="px-2 py-1 uppercase text-white inline-block font-bold rounded text-sm" style={{ backgroundColor: task.priority?.color }}>{task.priority?.label}</div>
                      )}

                      {editTask && (
                        <PrioritySelect value={priority} setValue={setPriority} style="dropdown" />
                      )}
                    </div>
                  </div>
                  
                  <div>
                    <label className="text-gray-600">Due Date</label>

                    <div className="mt-2">
                      {!editTask && (
                        <Fragment>
                          {task.dueDate && (
                            <div className="">{moment.utc(task.dueDate).local().format('MMM Do, YYYY')}</div>
                          )}

                          {!task.dueDate && (
                            <div className="text-gray-400">No Due Date</div>
                          )}
                        </Fragment>
                      )}

                      {editTask && (
                        <input type="date" id="text" className="bg-gray-50 block w-full border-0 rounded mt-2 focus:ring-0 focus:bg-gray-100" placeholder="" value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="text-gray-600">Author</label>

                  <div className="mt-2">
                    <div>
                      {task.user && (
                        <div className="whitespace-nowrap p-1 rounded-full bg-gray-50 inline-flex w-auto items-center mr-2 mb-2">
                          <div className="flex-shrink-0">
                            <UserAvatar user={task.user} className="w-7 h-7 rounded-full" />
                          </div>

                          <div className="px-2 text-gray-600">
                            {task.user?.firstName} {task.user?.lastName}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <label className="text-gray-600">Assigned To</label>

                  <div className="mt-2">
                    {!editTask && (
                      <div>
                        {task.taskUsers?.map((taskUser) => (
                          <div key={taskUser.id} className="whitespace-nowrap p-1 rounded-full bg-gray-50 inline-flex w-auto items-center mr-2 mb-2">
                            <div className="flex-shrink-0">
                              <UserAvatar user={taskUser.user} className="w-7 h-7 rounded-full" />
                            </div>

                            <div className="px-2 text-gray-600">
                              {taskUser.user?.firstName} {taskUser.user?.lastName}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {editTask && (
                      <UserSelect users={users} setUsers={setUsers} message="Assign this task to these team members..." />
                    )}
                  </div>
                </div>

                {editTask && (
                  <div className="text-center">
                    <button type="submit" className="bg-primary text-white px-5 py-2 rounded-full font-bold disabled:text-gray-400 disabled:bg-gray-100" disabled={(title.trim() == '' || users.length == 0)}>Update Task</button>
                  </div>
                )}

                <div>
                  <label className="text-gray-600">Discussion</label>

                  <div className="mt-2 bg-gray-50 rounded-t p-5 max-h-96 overflow-y-auto">
                    {task && (
                      <Messages moduleName="task" moduleId={task.id} />
                    )}
                  </div>

                  <div className="bg-gray-50 rounded-b p-2">
                    {task && (
                      <ComposeMessage moduleName="task" moduleId={task.id} />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </Modal>
      <DeleteTask show={deleteTask} setShow={setDeleteTask} task={deleteTask} taskDeleted={taskDeleted} />
    </>
  );
};


export default TaskDetails;