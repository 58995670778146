import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';

import Modal from 'components/modal';
import UserSelect from 'components/user-select';
import PrioritySelect from 'components/priority-select';

import { useAuth } from 'hooks/useAuth';
import { useApi } from 'hooks/useApi';
import { useSocket } from 'hooks/useSocket';


const NewTask = (props) => {
    const socket = useSocket();
    const api = useApi();

    const [title, setTitle]         = useState('');
    const [dueDate, setDueDate]     = useState(moment().format('YYYY-MM-DD'));
    const [users, setUsers]         = useState([]);
    const [priority, setPriority]   = useState(5);


    const saveTask = (event) => {
        event.preventDefault();

        return new Promise((resolve, reject) => {
            const request = {
                title       : title,
                dueDate     : moment(dueDate).utc().toISOString(),
                users       : users,
                priority    : priority
            };

            api.post('task', request).then((response) => {
                const task = response.data;

                props.setShow(false);

                resolve(task);
            });
        });
    };


    useEffect(() => {
        if (props.show)
        {
            //Restore default values
            setTitle('');
            setDueDate(moment().format('YYYY-MM-DD'));
            setUsers([]);
            setPriority(5);
        }
    }, [props.show]);


    return (
        <Modal show={props.show} setShow={props.setShow} className="max-w-3xl">
            <div className="pl-4 pr-4 pt-4">
                <div className="flex items-center">
                    <div className="pr-4">
                        <FontAwesomeIcon className="text-primary inline-block" icon={['fal', 'plus-circle']} size="2x" />
                    </div>

                    <div className="flex-grow">
                        <span className="text-xl">New Task</span>
                    </div>

                    <div>
                        <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                            <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="p-8">
                <form className="space-y-6" onSubmit={saveTask}>
                    <div>
                        <input type="text" id="text" className="text-lg bg-gray-50 block w-full border-0 rounded focus:ring-0 focus:bg-gray-100" placeholder="Enter a title for your new task..." onChange={(e) => setTitle(e.target.value)} required />
                    </div>

                    <div className="grid grid-cols-2 gap-8">
                        <div>
                            <label>Priority</label>
                            <div className="mt-2">
                                <PrioritySelect value={priority} setValue={setPriority} />
                            </div>
                        </div>

                        <div>
                            <label>Due Date</label>
                            <input type="date" id="text" className="text-lg bg-gray-50 block w-full border-0 rounded mt-2 focus:ring-0 focus:bg-gray-100" placeholder="" value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                        </div>
                    </div>

                    <div>
                        <UserSelect users={users} setUsers={setUsers} message="Assign this task to these team members..." />
                    </div>

                    <div className="text-center">
                        <button type="submit" className="bg-primary text-white px-5 py-2 rounded-full font-bold disabled:text-gray-400 disabled:bg-gray-100" disabled={(title.trim() == '' || users.length == 0)}>Create Task</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};


export default NewTask;