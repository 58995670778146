import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from 'hooks/useAuth';
import { ApiProvider } from 'hooks/useApi';
import { SocketProvider } from 'hooks/useSocket';
import { DownloadsProvider } from 'hooks/useDownloads';
import { DataProvider } from 'hooks/useData';

import { Toaster } from 'react-hot-toast';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';


library.add(fas, far, fal, fad);

ReactDOM.render(
	<>
		<ApiProvider>
			<AuthProvider>
				<SocketProvider>
					<DownloadsProvider>
						<DataProvider>
							<App />	
						</DataProvider>
					</DownloadsProvider>
				</SocketProvider>
			</AuthProvider>
		</ApiProvider>

		<Toaster />
	</>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
