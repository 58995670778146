import { useState, useEffect, useRef } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useApi } from 'hooks/useApi';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import Modal from 'components/modal';

import Logo from 'img/logo-white-text.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ViewLogin = function () {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [sendingPasswordResetInstructions, setSendingPasswordResetInstructions] = useState(false);

  const forgotPasswordEmailInput = useRef(null);

  const history = useHistory();
  const auth = useAuth();
  const api = useApi();

  const login = (e) => {
    e.preventDefault();

    setLoading(true);

    auth.login(email, password).then((response) => {
      localStorage.setItem('email', email);

      history.push('/home');
    })
      .catch(() => {
        setLoading(false);

        toast(
          (t) => (
            <div className="flex gap-4">
              <div>
                <FontAwesomeIcon icon={['far', 'warning']} className="text-primary" size="3x" />
              </div>
              <div>
                Invalid email address or password. Please check your credentials and try again.
              </div>
              <div>
                <button type="button" className="" onClick={() => toast.dismiss(t.id)}>
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
            </div>
          ),
          {
            duration: 5000
          }
        );

        setPassword('');
      });
  };


  const sendPasswordResetInstructions = (e) => {
    e.preventDefault();

    setSendingPasswordResetInstructions(true);

    const request = {
      email: forgotPasswordEmail
    };

    api.post('auth/resetPasswordInstructions', request).then((response) => {
      toast(
        (t) => (
          <div className="flex gap-4">
            <div>
              <FontAwesomeIcon icon={['far', 'thumbs-up']} className="text-green-500" size="3x" />
            </div>
            <div>
              <div className="font-semibold">
                Instructions Sent
              </div>
              <div className="mt-1">We've sent further instructions to reset your password to your email.</div>
            </div>
            <div>
              <button type="button" className="" onClick={() => toast.dismiss(t.id)}>
                <FontAwesomeIcon icon={['far', 'times']} />
              </button>
            </div>
          </div>
        ),
        {
          duration: 5000
        }
      );

      setForgotPasswordEmail('');
      setShowForgotPassword(false);
    }).catch((error) => {

    }).finally(() => {
      setSendingPasswordResetInstructions(false);
    });
  };


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  useEffect(() => {
    if (showForgotPassword) {
      setTimeout(() => {
        if (forgotPasswordEmailInput.current) {
          forgotPasswordEmailInput.current.focus();
        }
      }, 100);
    }
  }, [showForgotPassword]);


  useEffect(() => {
    const email = localStorage.getItem('email');

    if (email) {
      setEmail(email);
    }
  }, []);


  return (
    <>
      <div className="h-screen flex flex-col login-bg justify-center">
        <div className="mx-auto" style={{ width: '440px' }}>
          <div>
            <img src={Logo} className="max-w-xs mx-auto" />
          </div>

          <div className="bg-white p-14 rounded-md drop-shadow-lg mt-5">
            <form className="space-y-5" onSubmit={login}>
              <div>
                <input type="email" id="email" className="form-control mt-1 text-lg" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} autoFocus />
              </div>

              <div>
                <input type="password" id="password" className="form-control mt-1 text-lg" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>

              <div>
                <button type="button" className="text-primary" onClick={() => setShowForgotPassword(true)}>Forget your password?</button>
              </div>

              <div className="text-center pt-5">
                <button type="submit" className="bg-primary text-white font-bold text-lg rounded-full px-10 py-2 disabled:bg-gray-100 disabled:text-gray-400 transition-all" disabled={!isValidEmail(email) || !password}>
                  {!loading && <span>Sign In</span>}

                  {loading && <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="lg" />}
                </button>
              </div>
            </form>
          </div>

          <div className="mt-4 text-center font-bold text-gray-300">An infodontics product</div>
        </div>
      </div>

      <Modal show={showForgotPassword} setShow={setShowForgotPassword}>
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-grow">
              <span className="text-xl">
                <FontAwesomeIcon icon={['far', 'key']} className="mr-3 text-primary" />Password Reset
              </span>
            </div>

            <div>
              <button type="button" onClick={() => setShowForgotPassword(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
              </button>
            </div>
          </div>
        </div>

        <div className="pl-10 pb-10 pr-10">
          <form onSubmit={sendPasswordResetInstructions}>
            <div>
              If you've lost your password or wish to reset it, enter your account email address in the field below and we'll send you further instructions.
            </div>

            <div className="mt-10">
              <input type="email" className="form-control text-lg" placeholder="Enter your email address" ref={forgotPasswordEmailInput} value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)} required />
            </div>

            <div className="mt-10 text-center">
              <button type="submit" className="bg-primary text-white font-bold text-lg rounded-full px-10 py-2 disabled:bg-gray-100 disabled:text-gray-400 transition-all" disabled={!isValidEmail(forgotPasswordEmail)}>
                Send Password Reset Instructions
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ViewLogin;