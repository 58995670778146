import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileIcon, defaultStyles } from 'react-file-icon';

import moment from 'moment';
import parse from 'html-react-parser';

import { useApi } from 'hooks/useApi';

import { getFileExtension, formatBytes } from 'helpers/files';

import UserAvatar from 'components/user-avatar';
import Modal from 'components/modal';

const DeleteMessage = (props) => {
    const api = useApi();

    const deleteMessage = () => {
        api.delete(`message/${props.message.id}`).then((response) => {
            props.setShow(false);
        });
    };

    return (
        <Fragment>
            {props.message && (
                <Modal show={(props.show) ? true : false} setShow={props.setShow}>
                    <div className="p-4">
                        <div className="flex items-center">
                            <div className="flex-grow">
                                <span className="text-xl">Delete Message</span>
                            </div>

                            <div>
                                <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                                    <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pl-4 pb-4 pr-4">
                        <div>Are you sure you want to delete this message? This cannot be undone.</div>

                        <div className="mt-2 py-2 px-3 rounded border border-gray-200 text-sm">
                            <div className="flex">
                                <div className="pt-1.5">
                                    <UserAvatar user={props.message.user} showStatus={false} className="w-8 h-8 rounded shadow" />
                                </div>

                                <div className="pl-2 flex-grow messages">
                                    <div>
                                        <span className="font-semibold">{props.message.user.firstName} {props.message.user.lastName}</span>
                                        <span className="text-xs ml-4 text-gray-500">{moment.utc(props.message.createdAt).local().format('MMM Do, YYYY [at] h:mm A')}</span>

                                        {props.message.edited && (
                                            <span className="ml-4 text-gray-400 text-xs">(edited)</span>
                                        )}
                                    </div>

                                    {(props.message.type.toLowerCase() == 'message') && (
                                        <div>
                                            {parse(props.message.message || '')}
                                        </div>
                                    )}

                                    {(props.message.type.toLowerCase() == 'file' && props.message.files) && (
                                        <div className="space-y-5 mt-3">
                                            {props.message.files.map((file, index) => (
                                                <div className="flex items-center" key={index}>
                                                    <div className="w-10">
                                                        <FileIcon extension={getFileExtension(file.name)} {...defaultStyles[getFileExtension(file.name)]} />
                                                    </div>
            
                                                    <div className="flex-grow pl-5">
                                                        <div className="font-bold">{file.name}</div>
                                                        <div className="text-gray-600 text-sm">{formatBytes(file.size * 1024)}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex mt-2 items-center">
                            <div className="flex-grow">

                            </div>

                            <div className="mt-2">
                                <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-white text-gray-700 border border-gray-200 mr-2" onClick={() => props.setShow(false)}>
                                    Cancel
                                </button>

                                <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-primary text-white" onClick={() => deleteMessage()}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    );
};


export default DeleteMessage;