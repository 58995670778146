import { useState, useEffect } from 'react';


import { useApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';

import UserAvatar from 'components/user-avatar';

const ViewProfile = () => {
    const api = useApi();
    const auth = useAuth();


    const [firstName, setFirstName]     = useState('');
    const [lastName, setLastName]       = useState('');



    useEffect(() => {
        if (auth.user)
        {
            setFirstName(auth.user.firstName);
            setLastName(auth.user.lastName);
        }
    }, [auth.user]);


    return (
        <div className="flex h-full">
            <div style={{ width : '320px' }} className="py-10 px-20 bg-gray-50 h-full border-r border-gray-200">
                <button className="relative group overflow-hidden rounded-full border border-gray-200 p-1 bg-white shadow">
                    <UserAvatar user={auth.user} className="w-full h-full rounded-full" />

                    <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-0 group-hover:bg-opacity-70 text-white text-center font-bold pt-16">
                        <span className="invisible group-hover:visible">Change</span>
                    </div>
                </button>
            </div>

            <div className="p-6">
                <h1 className="text-2xl text-gray-700">Your Profile</h1>
            </div>
        </div>
    );
};


export default ViewProfile;