import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from 'moment';

import { useData } from 'hooks/useData';
import { useApi } from 'hooks/useApi';

import EditMessage from 'components/edit-message';
import DeleteMessage from 'components/delete-message';
import EmojiPicker from 'components/emoji-picker';
import ImagePreview from 'components/image-preview';
import Message from 'components/message';


const Messages = ({ moduleName, moduleId, dateRange, setDateRange, highlightMessageId }) => {
  const api = useApi();
  const data = useData();

  const [editMessage, setEditMessage] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [loading, setLoading] = useState(true);


  const filteredMessages = () => {
    return data.messages.filter((message) => {
      return (message.moduleName.toLowerCase() == moduleName.toLowerCase() && message.moduleId == moduleId);
    });
  };


  const sortedMessages = () => {
    return filteredMessages().sort((messageA, messageB) => {
      const timestampA = moment.utc(messageA.createdAt).format('X');
      const timestampB = moment.utc(messageB.createdAt).format('X');

      if (timestampA == timestampB) {
        return 0;
      }
      else {
        return (timestampA > timestampB) ? 1 : -1;
      }
    });
  };


  const groupedMessages = () => {
    const dates = [];

    sortedMessages().forEach((message) => {
      const date = moment.utc(message.createdAt).local().format('M/D/YYYY');

      const existingDateEntry = dates.find((dateEntry) => {
        return (dateEntry.date == date);
      });

      if (existingDateEntry) {
        existingDateEntry.messages.push(message);
      }
      else {
        dates.push({
          date: date,
          dateFormatted: moment.utc(message.createdAt).local().format('MMMM Do'),
          messages: [
            message
          ]
        });
      }
    });

    return dates;
  };


  const dateTag = (date) => {
    if (moment(date).isSame(moment(), 'day')) {
      return 'Today - ' + moment(date).format('MMMM Do');
    }

    if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
      return 'Yesterday - ' + moment(date).format('MMMM Do');
    }

    if (moment(date).isSame(moment(), 'year')) {
      return moment(date).format('MMMM Do');
    }

    return moment(date).format('MMMM Do, YYYY');
  };


  const addReaction = (message, emoji) => {
    api.post(`message/${message.id}/reaction`, { emoji });
  };


  const deleteReaction = (message, reaction) => {
    api.delete(`message/${message.id}/reaction/${reaction.id}`);
  };


  const loadMessages = async () => {
    if (moduleName && moduleId) {
      setLoading(true);
      await data.loadMessagesByModuleNameModuleId(moduleName, moduleId, dateRange);
      setLoading(false);
    }
  };


  useEffect(() => {
    loadMessages();
  }, [moduleName, moduleId, dateRange]);


  return (
    <>
      <div className="messages">
        {loading && (
          <>
            <div className="text-center space-y-5 py-10">
              <div>
                <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="4x" />
              </div>

              <div className="text-gray-500">
                Loading messages... Just a moment
              </div>
            </div>
          </>
        )}

        {!loading && (
          <>
            {groupedMessages().map((date, index) => (
              <div key={index}>
                <div className="text-center sticky top-0">
                  <div className="inline-block border border-gray-300 rounded-full py-1 px-4 shadow text-sm font-bold bg-white">
                    {dateTag(date.date)}
                  </div>
                </div>

                <div className="border-t border-gray-200 mt-5"></div>

                {date.messages.map((message, index) => (
                  <Message
                    message={message}
                    highlightMessageId={highlightMessageId}
                    setImagePreview={setImagePreview}
                    addReaction={addReaction}
                    deleteReaction={deleteReaction}
                    setShowEmojiPicker={setShowEmojiPicker}
                    setDeleteMessage={setDeleteMessage}
                    setEditMessage={setEditMessage}
                    key={message.id} />
                ))}
              </div>
            ))}

            {groupedMessages().length == 0 && (
              <div className="text-center py-14">
                <div>
                  <FontAwesomeIcon icon={['fad', 'messages']} size="6x" className="text-primary" />
                </div>

                <div className="mt-4 text-xl">No Messages Found</div>
                <div className="mt-2 text-gray-600">Try adjusting the date range or send a new message.</div>
              </div>
            )}
          </>
        )}
      </div>

      <EditMessage message={editMessage} show={editMessage} setShow={setEditMessage} />
      <DeleteMessage message={deleteMessage} show={deleteMessage} setShow={setDeleteMessage} />
      <EmojiPicker show={showEmojiPicker} setShow={setShowEmojiPicker} emojiSelected={(emoji) => { addReaction(showEmojiPicker, emoji.emoji); setShowEmojiPicker(false); }} />
      <ImagePreview show={imagePreview} setShow={setImagePreview} file={imagePreview} />
    </>
  );
};


export default Messages;