import config from 'config';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-initials-sprites';
import { useAuth } from 'hooks/useAuth';

const UserAvatar = ({ user, className, showStatus }) => {
	const auth = useAuth();

	if (user?.avatarType) {
		if (user.avatarType.toUpperCase() == 'UPLOAD' && user.photo) {
			return (
				<div className="relative">
					<img className={className} src={config.apiUrl + 'file/' + user.accountId + '/' + user.photo.name} />
					{(showStatus) && <span className="absolute bottom-0 right-0 w-3.5 h-3.5 rounded-full bg-green-600 border-2 border-white dark:border-gray-800"></span>}
				</div>
			);
		}
	
		if (user.avatarType.toUpperCase() == 'AUTO' || user.avatarType.toUpperCase() == 'MANUAL') {
			let avatarOptions = {
				seed: `${user.firstName} ${user.lastName}`,
				dataUri: true,
				fontSize: 40,
				bold: true
			};
	
			if (user.avatarType.toUpperCase() == 'MANUAL' && user.avatarColor) {
				avatarOptions.background = user.avatarColor;
			}
	
			let svg = createAvatar(style, avatarOptions);
	
			return (
				<div className="relative">
					<img className={className} src={svg} />
					{(showStatus) && <span className="absolute bottom-0 right-0 w-3.5 h-3.5 rounded-full bg-green-600 border-2 border-white dark:border-gray-800"></span>}
				</div>
			);
		}
	}

	return null;
};


export default UserAvatar;