import moment from 'moment';
import { useState, useEffect } from 'react';
import { useHistory }  from 'react-router-dom';

import TaskCard from 'components/task-card';
import TaskDetails from 'components/task-details';

import { useAuth } from 'hooks/useAuth';
import { useData } from 'hooks/useData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChannelIcon from 'components/channel-icon';
import UserAvatar from 'components/user-avatar';


const ViewHome = () => {
  const auth = useAuth();
  const data = useData();
  const history = useHistory();

  const [selectedTaskId, setSelectedTaskId] = useState(false);
  
  const assignedTasks = () => {
    return data.tasks.filter((task) => {
      return task.taskUsers.map((taskUser) => taskUser.userId).indexOf(auth.user.id) >= 0;
    });
  };

  const incompleteTasks = () => {
    return assignedTasks().filter((task) => {
      return !task.completed;
    });
  };

  const tasksDueToday = () => {
    return incompleteTasks().filter((task) => {
      return moment().startOf('day').diff(moment.utc(task.dueDate).local()) == 0;
    });
  };

  const tasksDueThisWeek = () => {
    return incompleteTasks().filter((task) => {
      if (moment().startOf('day').diff(moment.utc(task.dueDate).local()) == 0) return false;

      return moment().startOf('isoWeek').diff(moment.utc(task.dueDate).local().startOf('isoWeek')) == 0;
    });
  };

  const pastDueTasks = () => {
    return incompleteTasks().filter((task) => {
      return (moment().startOf('day').diff(moment.utc(task.dueDate).local()) > 0);
    });
  };

  const selectedTask = () => {
    const selectedTask = data.tasks.find((task) => {
      return (task.id == selectedTaskId);
    });

    return (selectedTask) ? selectedTask : false;
  };

  const taskClicked = (task) => {
    setSelectedTaskId(task.id);
  };

  const missedGabMessages = () => {
    const missedGabs = [];

    

    return missedGabs;
  };  


  return (
    <>
    <div className="p-10">
      <div className="text-3xl text-gray-600">Welcome back {auth.user?.firstName}!</div>

      <div className="grid grid-cols-1 lg:grid-cols-2 mt-6 gap-6">
        <div>
          <div className="flex items-center gap-x-3">
            <div>
              <FontAwesomeIcon icon={['fad', 'messages']} size="lg" className="text-rose-400" />
            </div>
            <div className="flex-1">
              <span className="text-xl text-gray-500">Gab messages you may have missed</span>
            </div>
          </div>

          <div className="mt-4 space-y-2">
            {missedGabMessages().length > 0 && (
              <>
                {missedGabMessages().map((missedGabMessage, index) => (
                  <div key={index}>
                    {missedGabMessage.moduleName == 'channel' && (
                      <div className="flex items-center gap-x-4 hover:bg-gray-100 px-2 py-1 cursor-pointer rounded">
                        <div>
                          <ChannelIcon className="w-10 h-10 bg-white shadow" name={missedGabMessage.channel?.title} />
                        </div>

                        <div className="flex-1">
                          <div className="text-gray-700 font-medium text-lg">
                            {missedGabMessage.channel?.title}
                          </div>

                          <div className="text-sm text-gray-500">
                            {missedGabMessage.unreadMessageCount} New Messages
                          </div>
                        </div>
                      </div>
                    )}

                    {missedGabMessage.moduleName == 'direct' && (
                      <div className="flex items-center gap-x-4 hover:bg-gray-100 px-2 py-1 cursor-pointer rounded">
                        <div>
                          <UserAvatar className="w-10 h-10 shadow border-2 border-white rounded-full" user={missedGabMessage.user} /> 
                        </div>

                        <div className="flex-1">
                          <div className="text-gray-700 font-medium text-lg">
                            {missedGabMessage.user?.firstName} {missedGabMessage.user?.lastName}
                          </div>

                          <div className="text-sm text-gray-500">
                            {missedGabMessage.unreadMessageCount} New Message
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        <div>
          <div>
             <div className="flex items-center gap-x-3">
              <div>
                <FontAwesomeIcon icon={['fad', 'calendar-day']} size="lg" className="text-rose-400" />
              </div>
              <div className="flex-1">
                <span className="text-xl text-gray-500">Tasks due today</span>
              </div>
            </div>

            {tasksDueToday().length > 0 && (
              <div className="mt-4 border border-gray-200 rounded divide-y divide-gray-200 overflow-hidden shadow-sm">
                {tasksDueToday().map((task) => (
                  <TaskCard task={task} key={task.id} taskClicked={taskClicked} />
                ))}
              </div>
            )}

            {tasksDueToday().length == 0 && (
              <div className="mt-4">
                <div className="py-4 px-8 bg-gray-50 rounded-xl border-b-2 border-gray-200 text-center">
                  <div>
                    <FontAwesomeIcon icon={['fad', 'party-horn']} size="5x" className="text-gray-400" />
                  </div>

                  <div className="mt-3 text-gray-700 text-xl font-semibold">You're all caught up!</div>
                  <div className="text-gray-600">No tasks due today!</div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-10">
            <div className="flex items-center gap-x-3">
              <div>
                <FontAwesomeIcon icon={['fad', 'calendar-week']} size="lg" className="text-rose-400" />
              </div>
              <div className="flex-1">
                <span className="text-xl text-gray-500">Tasks due this week</span>
              </div>
            </div>
            
            {tasksDueThisWeek().length > 0 && (
              <div className="mt-4 border border-gray-200 rounded divide-y divide-gray-200 overflow-hidden shadow-sm">
                {tasksDueThisWeek().map((task) => (
                  <TaskCard task={task} key={task.id} taskClicked={taskClicked} />
                ))}
              </div>
            )}

            {tasksDueThisWeek().length == 0 && (
              <div className="mt-4">
                <div className="py-4 px-8 bg-gray-50 rounded-xl border-b-2 border-gray-200 text-center">
                  <div>
                    <FontAwesomeIcon icon={['fad', 'face-party']} size="5x" className="text-gray-400" />
                  </div>

                  <div className="mt-3 text-gray-700 text-xl font-semibold">Great work!</div>
                  <div className="text-gray-600">No tasks due this week!</div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-10">
            <div className="flex items-center gap-x-3">
              <div>
                <FontAwesomeIcon icon={['fad', 'calendar-exclamation']} size="lg" className="text-rose-400" />
              </div>
              <div className="flex-1">
                <span className="text-xl text-gray-500">Past due tasks</span>
              </div>
            </div>

            {pastDueTasks().length > 0 && (
              <div className="mt-4 border border-gray-200 rounded divide-y divide-gray-200 overflow-hidden shadow-sm">
                {pastDueTasks().map((task) => (
                  <TaskCard task={task} key={task.id} taskClicked={taskClicked} />
                ))}
              </div>
            )}

            {pastDueTasks().length == 0 && (
              <div className="mt-4">
                <div className="py-4 px-8 bg-gray-50 rounded-xl border-b-2 border-gray-200 text-center">
                  <div>
                    <FontAwesomeIcon icon={['fad', 'calendar-heart']} size="5x" className="text-gray-400" />
                  </div>

                  <div className="mt-3 text-gray-700 text-xl font-semibold">Lookin' good!</div>
                  <div className="text-gray-600">You don't have any past due tasks!</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    
    <TaskDetails show={selectedTaskId} setShow={setSelectedTaskId} task={selectedTask()} />
    </>
  );
};


export default ViewHome;