import { useState, useEffect, Fragment, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from '@headlessui/react';

import Modal from 'components/modal';
import UserAvatar from 'components/user-avatar';
import DeleteChannel from 'components/delete-channel';
import ChannelAddUsers from 'components/channel-add-users';

import { useApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { useData } from 'hooks/useData';

import classNames from 'classnames';
import moment from 'moment';

const ChannelSettings = ({ channelId, show, setShow }) => {
  const api = useApi();
  const auth = useAuth();
  const data = useData();

  const [title, setTitle] = useState('');
  const [archived, setArchived] = useState(false);
  const [tab, setTab] = useState('about');

  const refTitleInput = useRef(null);

  const [editTitle, setEditTitle] = useState(false);
  const [showDeleteChannel, setShowDeleteChannel] = useState(false);
  const [showAddUsers, setShowAddUsers] = useState(false);


  const channel = () => {
    if (channelId) {
      return data.channels.find((channel) => {
        return (channel.id == channelId);
      });
    }

    return {};
  };


  const editChannel = (event) => {
    event?.preventDefault();

    data.updateChannel(channel()?.id, {
      title: title
    }).then((response) => {
      setEditTitle(false);
    });
  };

  
  const toggleArchived = () => {
    data.updateChannel(channel()?.id, {
      archived: !archived
    });
  };


  const channelDeleted = () => {
    setShow(false);
  };


  const usersAdded = () => {

  };


  const removeUser = (userId) => {
    api.delete(`channel/${channel()?.id}/removeUser/${userId}`);
  };


  useEffect(() => {
    if (show) {
      setTab('about');
    }
  }, [channelId, show]);


  useEffect(() => {
    refTitleInput.current?.select();
  }, [editTitle]);

  
  useEffect(() => {
    const currentChannel = channel();

    if (currentChannel) {
      setTitle(currentChannel.title);
      setArchived(currentChannel.archived);
    }
  }, [channel()]);


  return (
    <Fragment>
      <Modal show={show} setShow={setShow}>
        <div className="p-4">
          <div className="flex items-center">
            <div className="pr-4">
              <FontAwesomeIcon className="text-primary inline-block" icon={['fal', 'square-sliders']} size="2x" />
            </div>

            <div className="flex-grow">
              <span className="text-xl">Gab Session Settings</span>
            </div>

            <div>
              <button type="button" onClick={() => setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
              </button>
            </div>
          </div>
        </div>

        <div className="pl-6 pb-6 pr-6">
          <div className="flex border-b border-gray-300">
            <button type="button" className={classNames('px-3 py-1 border-b-2 border-transparent', { 'font-bold border-primary': tab == 'about' })} onClick={() => setTab('about')}>About</button>
            <button type="button" className={classNames('px-3 py-1 border-b-2 border-transparent', { 'font-bold border-primary': tab == 'members' })} onClick={() => setTab('members')}>Team Members</button>
            <button type="button" className={classNames('px-3 py-1 border-b-2 border-transparent', { 'font-bold border-primary': tab == 'settings' })} onClick={() => setTab('settings')}>Settings</button>
          </div>

          <div className="mt-6">
            {/* About tab */}
            <div className={classNames({ 'hidden': tab !== 'about', 'block': tab == 'about' })}>
              <div className="rounded-lg bg-gray-50 py-3 px-4">
                <form onSubmit={editChannel}>
                  <div className="flex items-center">
                    <div className="font-bold flex-grow text-gray-600">Gab Session Title</div>
                    <div>
                      {!editTitle && <button type="button" className="text-primary" onClick={() => { setEditTitle(true); }}>Edit</button>}
                      {editTitle && <button type="submit" className="text-emerald-500">Save</button>}
                    </div>
                  </div>

                  {!editTitle && (
                    <div className="text-lg"># {channel()?.title}</div>
                  )}

                  {editTitle && (
                    <div className="flex items-center">
                      <div className="text-xl font-bold text-gray-600 pr-4 pt-1">
                        <span>#</span>
                      </div>

                      <div className="flex-grow">
                        <input type="text" className="form-control mt-2 text-lg" placeholder="Channel title..." value={title} onChange={(e) => setTitle(e.target.value)} ref={refTitleInput} />
                      </div>
                    </div>
                  )}
                </form>
              </div>

              <div className="rounded-lg bg-gray-50 py-3 px-4 mt-6">
                <div className="font-bold flex-grow text-gray-600">Created</div>
                {channel() && (
                  <div>
                    {moment.utc(channel().createdAt).format('MMMM Do, YYYY')}
                  </div>
                )}
              </div>
            </div>


            {/* Members tab */}
            <div className={classNames({ 'hidden': tab !== 'members', 'block': tab == 'members' })}>
              <div className="flex">
                <div className="flex-grow">
                  {channel()?.channelUsers?.length} team members in gab session.
                </div>

                <div>
                  <button type="button" className="bg-gray-600 px-4 py-1 text-white rounded hover:bg-gray-700" onClick={() => setShowAddUsers(true)}>
                    <FontAwesomeIcon icon={['far', 'user-plus']} className="mr-2" />Add Team Members
                  </button>
                </div>
              </div>

              <div className="max-h-96 overflow-y-auto mt-5">
                {channel()?.channelUsers?.map((channelUser) => (
                  <div className="flex p-2 hover:bg-gray-50 items-center" key={channelUser.id}>
                    <div className="flex-shrink-0">
                      <UserAvatar user={channelUser.user} showStatus={false} className="w-10 h-10 rounded-full border-2 border-white shadow" />
                    </div>

                    <div className="pl-4 flex-grow">
                      <span className="text-lg">{channelUser.user?.firstName} {channelUser.user?.lastName}</span> {(channelUser.user?.id == auth.user.id) && (<span className="text-gray-500">(you)</span>)}
                    </div>

                    <div className="px-2">
                      {channelUser.userId !== auth.user.id && (
                        <button type="button" className="text-gray-500 hover:text-gray-900" onClick={() => removeUser(channelUser.userId)}>
                          <FontAwesomeIcon icon={['far', 'minus-circle']} />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Settings tab */}
            <div className={classNames({ 'hidden': tab !== 'settings', 'block': tab == 'settings' })}>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <Switch
                    checked={channel()?.archived}
                    onChange={toggleArchived}
                    className={classNames(
                      channel()?.archived ? 'bg-primary' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        channel()?.archived ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </div>
                <div className="ml-3 leading-6">
                  <label htmlFor="archived" className="font-medium text-gray-900">
                    Archived
                  </label>
                  <p id="archived-description" className="text-gray-500">
                    Archive this Gab Session to hide it from the session list and prevent new messages.
                  </p>
                </div>
              </div>
              
              <div className="mt-6">
                <button type="button" className="py-2 px-4 bg-rose-700 text-white rounded font-bold hover:bg-rose-600" onClick={() => setShowDeleteChannel(true)}>
                  <FontAwesomeIcon icon={['far', 'trash-alt']} className="mr-2" />Delete Gab Session
                </button>
              </div>
            </div>
          </div>
        </div>

        <DeleteChannel show={showDeleteChannel} setShow={setShowDeleteChannel} channelDeleted={channelDeleted} channel={channel()} />
        <ChannelAddUsers show={showAddUsers} setShow={setShowAddUsers} channel={channel()} usersAdded={usersAdded} />
      </Modal>
    </Fragment>
  );
};


export default ChannelSettings;