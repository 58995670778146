import { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useHistory } from 'react-router-dom';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment';
import classNames from "classnames";

import { useApi } from 'hooks/useApi';
import { useData } from 'hooks/useData';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { useSocket } from 'hooks/useSocket';

import Tooltip from 'components/tooltip';
import NewTask from 'components/new-task';
import UserAvatar from "components/user-avatar";
import TaskDetails from 'components/task-details';
import DeleteTask from 'components/delete-task';


const SortIndicator = ({ sortBy, sortDirection, field }) => {
  if (sortBy == field) {
    return (sortDirection == 'desc') ? <FontAwesomeIcon className="text-gray-500 ml-2" icon={['far', 'sort-down']} /> : <FontAwesomeIcon className="text-gray-500 ml-2" icon={['far', 'sort-up']} />;
  }

  return null;
};


const ViewTasks = () => {
  const api = useApi();
  const auth = useAuth();
  const data = useData();
  const query = useQuery();
  const socket = useSocket();
  const history = useHistory();
  const location = useLocation();
  

  const [showSidebar, setShowSidebar] = useState(true);
  const [showNewTask, setShowNewTask] = useState(false);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [highlightTaskId, setHighlightTaskId] = useState(null);

  const filterTypeOptions = [
    {
      label: 'All Tasks',
      value: null
    },
    {
      label: 'Assigned Tasks',
      value: 'assigned'
    },
    {
      label: 'Created Tasks',
      value: 'created'
    }
  ];

  const filterCompletedOptions = [
    {
      label: 'All Tasks',
      value: null
    },
    {
      label: 'Completed Tasks',
      value: 'completed'
    },
    {
      label: 'Incomplete Tasks',
      value: 'incomplete'
    }
  ];

  const filterPriorityOptions = [
    {
      label: 'Any Priority',
      value: null,
      color: '#d1d5db'
    },
    ...data.taskPriorities.map((priority) => {
      return {
        label: priority.label,
        value: priority.value,
        color: priority.color
      }
    })
  ];


  const filterAuthorOptions = [{
    label: 'Anyone',
    value: null
  }, ...data.users.map((user) => {
    return {
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
      user: user
    }
  })];


  const filterDueDateOptions = [
    {
      label: 'All Due Dates',
      dateStart: null,
      dateEnd: null
    },
    {
      label: 'Past Due',
      dateStart: moment(0).utc().toISOString(),
      dateEnd: moment().startOf('day').subtract(1, 'day').endOf('day').utc().toISOString()
    },
    {
      label: 'Due Today',
      dateStart: moment().startOf('day').utc().toISOString(),
      dateEnd: moment().endOf('day').utc().toISOString()
    },
    {
      label: 'Due Tomorrow',
      dateStart: moment().startOf('day').add(1, 'day').utc().toISOString(),
      dateEnd: moment().startOf('day').add(1, 'day').endOf('day').utc().toISOString()
    },
    {
      label: 'Due This Week',
      dateStart: moment().startOf('isoWeek').utc().toISOString(),
      dateEnd: moment().startOf('isoWeek').add(6, 'day').endOf('day').utc().toISOString()
    },
    {
      label: 'Due Next Week',
      dateStart: moment().startOf('isoWeek').add(7, 'day').utc().toISOString(),
      dateEnd: moment().startOf('isoWeek').add(13, 'day').endOf('day').utc().toISOString()
    }
  ];


  const isTaskAssigned = (task) => {
    return (task.taskUsers.map((taskUser) => taskUser.userId).indexOf(auth.user.id) >= 0);
  }


  const isTaskAuthor = (task) => {
    return (task.userId == auth.user.id);
  };


  const isTaskUnread = (taskId) => {
    const matchingUserUnreadTask = data.userUnreadTasks.find((userUnreadTask) => {
      return (userUnreadTask.taskId === taskId);
    });

    return (matchingUserUnreadTask) ? true : false;
  };


  const unreadTasks = () => {
    return data.tasks.filter((task) => {
      return isTaskUnread(task.id);
    });
  };


  const getUnreadMessageCount = (taskId) => {
    return data.userUnreadMessages.filter((userUnreadMessage) => {
      return (userUnreadMessage.userId == auth.user.id && userUnreadMessage.moduleName.toLowerCase() == 'task' && userUnreadMessage.moduleId == taskId);
    }).length;
  };


  const getAssignedTasksUnreadMessageCount = () => {
    return data.tasks.map((task) => {
      return (isTaskAssigned(task)) ? getUnreadMessageCount(task.id) : 0;
    });
  };


  const getAssignedTasksWithUnreadMessages = () => {
    return data.tasks.filter((task) => {
      if (isTaskAssigned(task)) {
        if (getUnreadMessageCount(task.id)) return true;
      }

      return false;
    });
  };


  const getCreatedTasksWithUnreadMessages = () => {
    return data.tasks.filter((task) => {
      if (isTaskAuthor(task)) {
        if (getUnreadMessageCount(task.id)) return true;
      }

      return false;
    });
  };


  const getQuickLinks = () => {
    return [
      {
        label: 'Assigned to Me',
        links: [
          {
            key: 'assignedMyTasks',
            label: 'My Tasks',
            icon: ['far', 'list-check'],
            filters: {
              type: 'assigned',
              completed: 'incomplete',
              priority: null,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: null,
              unreadMessages: null
            }
          },
          {
            key: 'assignedNewTasks',
            label: 'New Tasks',
            icon: ['far', 'envelope'],
            number: unreadTasks().length,
            filters: {
              type: 'assigned',
              completed: null,
              priority: null,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: true,
              unreadMessages: null
            }
          },
          {
            key: 'assignedNewMessages',
            label: 'New Messages',
            icon: ['far', 'messages'],
            number: getAssignedTasksWithUnreadMessages().length,
            filters: {
              type: 'assigned',
              completed: null,
              priority: null,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: null,
              unreadMessages: true
            }
          },
          {
            key: 'assignedDueToday',
            label: 'Due Today',
            icon: ['far', 'calendar-day'],
            filters: {
              type: 'assigned',
              completed: 'incomplete',
              priority: null,
              author: null,
              dueDateStart: moment().startOf('day').utc().toISOString(),
              dueDateEnd: moment().endOf('day').utc().toISOString(),
              unreadTasks: null,
              unreadMessages: null
            }
          },
          {
            key: 'assignedDueThisWeek',
            label: 'Due This Week',
            icon: ['far', 'calendar-week'],
            filters: {
              type: 'assigned',
              completed: 'incomplete',
              priority: null,
              author: null,
              dueDateStart: moment().startOf('isoWeek').utc().toISOString(),
              dueDateEnd: moment().startOf('isoWeek').add(6, 'day').endOf('day').utc().toISOString(),
              unreadTasks: null,
              unreadMessages: null
            }
          },
          {
            key: 'assignedHighPriority',
            label: 'High Priority',
            icon: ['far', 'diamond-exclamation'],
            filters: {
              type: 'assigned',
              completed: 'incomplete',
              priority: 1,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: null,
              unreadMessages: null
            }
          }
        ]
      },
      {
        label: 'Created Tasks',
        links: [
          {
            key: 'createdNewMessages',
            label: 'New Messages',
            icon: ['far', 'messages'],
            number: getCreatedTasksWithUnreadMessages().length,
            filters: {
              type: 'created',
              completed: null,
              priority: null,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: null,
              unreadMessages: true
            }
          },
          {
            key: 'createdIncomplete',
            label: 'Incomplete Tasks',
            icon: ['far', 'square'],
            filters: {
              type: 'created',
              completed: 'incomplete',
              priority: null,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: null,
              unreadMessages: null
            }
          },
          {
            key: 'createdCompleted',
            label: 'Completed Tasks',
            icon: ['far', 'square-check'],
            filters: {
              type: 'created',
              completed: 'completed',
              priority: null,
              author: null,
              dueDateStart: null,
              dueDateEnd: null,
              unreadTasks: null,
              unreadMessages: null
            }
          }
        ]
      }
    ];
  };

  const [viewType, setViewType] = useState('list');

  const [filterType, setFilterType] = useState('assigned');
  const [filterCompleted, setFilterCompleted] = useState('incomplete');
  const [filterDueDateStart, setFilterDueDateStart] = useState(null);
  const [filterDueDateEnd, setFilterDueDateEnd] = useState(null);
  const [filterPriority, setFilterPriority] = useState(null);
  const [filterAuthor, setfilterAuthor] = useState(null);
  const [filterAssignedTo, setFilterAssignedTo] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterUnreadMessages, setFilterUnreadMessages] = useState(null);
  const [filterUnreadTasks, setFilterUnreadTasks] = useState(null);

  const [sortBy, setSortBy] = useState('dueDate');
  const [sortDirection, setSortDirection] = useState('asc');

  const [calendarStart, setCalendarStart] = useState('');
  const [calendarEnd, setCalendarEnd] = useState('');
  const [initialView, setInitialView] = useState('dayGridMonth');

  const [selectedQuickLink, setSelectedQuickLink] = useState(getQuickLinks()[0].links[0]);

  const resetFilters = () => {
    selectQuickLink(getQuickLinks()[0].links[0]);
  };


  const selectQuickLink = (selectedQuickLink) => {
    Object.keys(selectedQuickLink.filters).forEach((filterKey) => {
      const filterValue = selectedQuickLink.filters[filterKey];

      if (filterKey == 'type') setFilterType(filterValue);
      if (filterKey == 'completed') setFilterCompleted(filterValue);
      if (filterKey == 'priority') setFilterPriority(filterValue);
      if (filterKey == 'author') setfilterAuthor(filterValue);
      if (filterKey == 'dueDateStart') setFilterDueDateStart(filterValue);
      if (filterKey == 'dueDateEnd') setFilterDueDateEnd(filterValue);
      if (filterKey == 'unreadMessages') setFilterUnreadMessages(filterValue);
      if (filterKey == 'unreadTasks') setFilterUnreadTasks(filterValue);
    });

    setSelectedQuickLink(selectedQuickLink);
  };


  const applySort = (field) => {
    setSortBy(field);

    if (field == sortBy) {
      setSortDirection(sortDirection == 'desc' ? 'asc' : 'desc');
    }
    else {
      setSortDirection('desc');
    }
  };


  const selectedFilterType = () => {
    return filterTypeOptions.find((filterTypeOption) => {
      return (filterTypeOption.value == filterType);
    });
  };

  const selectedFilterCompleted = () => {
    return filterCompletedOptions.find((filterCompletedoption) => {
      return (filterCompletedoption.value == filterCompleted);
    });
  };

  const selectedFilterPriority = () => {
    return filterPriorityOptions.find((filterPriorityOption) => {
      return (filterPriorityOption.value == filterPriority);
    });
  };

  const selectedfilterAuthor = () => {
    return filterAuthorOptions.find((filterAuthorOption) => {
      return (filterAuthorOption.value == filterAuthor);
    });
  };

  const selectedFilterDueDate = () => {
    return filterDueDateOptions.find((filterDueDateOption) => {
      return (filterDueDateStart == filterDueDateOption.dateStart && filterDueDateEnd == filterDueDateOption.dateEnd);
    });
  };


  const updateTask = (id, task) => {
    api.put(`task/${id}`, task);
  };


  const sortedTasks = () => {
    return data.tasks.sort((taskA, taskB) => {
      const sortA = (sortDirection == 'desc') ? 1 : -1;
      const sortB = (sortDirection == 'desc') ? -1 : 1;

      if (sortBy == 'completed') {
        return (taskB.completed) ? sortA : sortB;
      }

      if (sortBy == 'title') {
        return (taskA.title == taskB.title) ? 0 : (taskA.title < taskB.title) ? sortA : sortB;
      }

      if (sortBy == 'author') {
        return (taskA.author.firstName == taskB.author.firstName) ? 0 : (taskA.author.firstName < taskB.author.firstName) ? sortA : sortB;
      }

      if (sortBy == 'users') {
        return (taskA.users.length == taskB.users.length) ? 0 : (taskA.users.length < taskB.users.length) ? sortA : sortB;
      }

      if (sortBy == 'priority') {
        return (taskA.priority.value == taskB.priority.value) ? 0 : (taskA.priority.value > taskB.priority.value) ? sortA : sortB;
      }

      if (sortBy == 'dueDate') {
        const timestampA = (taskA.dueDate !== null) ? moment.utc(taskA.dueDate).format('X') : 0;
        const timestampB = (taskB.dueDate !== null) ? moment.utc(taskB.dueDate).format('X') : 0;

        return (timestampA == timestampB) ? 0 : (timestampA < timestampB) ? sortA : sortB;
      }
    });
  };


  const filteredTasks = () => {
    let tasks = sortedTasks();

    tasks = tasks.filter((task) => {
      if (task.taskUsers.map((taskUser) => taskUser.userId).indexOf(auth.user.id) >= 0 || task.user.id == auth.user.id) {
        return true;
      }

      return false;
    });

    tasks = tasks.filter((task) => {
      if (filterType == 'assigned') {
        if (task.taskUsers.map((taskUser) => taskUser.userId).indexOf(auth.user.id) == -1) return false;
      }

      if (filterType == 'created') {
        if (task.user.id !== auth.user.id) return false;
      }

      if (filterCompleted == 'completed') {
        if (task.completed == false) return false;
      }

      if (filterCompleted == 'incomplete') {
        if (task.completed == true) return false;
      }

      if (filterPriority) {
        if (task.priority.value !== filterPriority) return false;
      }

      if (filterAuthor) {
        if (task.user.id !== filterAuthor) return false;
      }

      if (filterDueDateStart) {
        if (moment.utc(task.dueDate).format('X') < moment.utc(filterDueDateStart).format('X')) return false;
      }

      if (filterDueDateEnd) {
        if (moment.utc(task.dueDate).format('X') > moment.utc(filterDueDateEnd).format('X')) return false;
      }

      if (filterSearch) {
        if (task.title.toLowerCase().indexOf(filterSearch.toLowerCase()) <= -1) return false;
      }

      if (filterUnreadMessages !== null) {
        if (filterUnreadMessages == true && getUnreadMessageCount(task.id) <= 0) return false;
        if (filterUnreadMessages == false && getUnreadMessageCount(task.id) > 0) return false;
      }

      if (filterUnreadTasks) {
        if (filterUnreadTasks == true && isTaskUnread(task.id) == false) return false;
        if (filterUnreadTasks == false && isTaskUnread(task.id) == true) return false;
      }

      return true;
    });

    return tasks;
  };


  const calendarEvents = () => {
    const timestampCalendarStart = moment(calendarStart).format('X');
    const timestampCalendarEnd = moment(calendarEnd).format('X');

    return filteredTasks().filter((task) => {
      if (task.dueDate) {
        const timestampTaskDueDate = moment.utc(task.dueDate).local().format('X');

        if (timestampTaskDueDate >= timestampCalendarStart && timestampTaskDueDate < timestampCalendarEnd) {
          return true;
        }
      }

      return false;
    }).map((task) => {
      return {
        id: task.id,
        title: task.completed ? `✔ ${task.title}` : task.title,
        backgroundColor: task.priority.color,
        borderColor: task.priority.color,
        date: moment.utc(task.dueDate).local().format('YYYY-MM-DD'),
        classNames: 'cursor-pointer'
      };
    });
  };


  const calendarChangeDate = (payload) => {
    setCalendarStart(payload.startStr);
    setCalendarEnd(payload.endStr);
  };


  const calendarEventClicked = (event) => {
    const taskId = event.event.id;

    const task = data.tasks.find((task) => {
      return (parseInt(task.id) == parseInt(taskId));
    });

    if (task) {
      setShowTaskDetails(task);
    }
  };


  const selectedTaskDetails = () => {
    if (showTaskDetails) {
      return data.tasks.find((task) => {
        return (task.id == showTaskDetails.id);
      });
    }

    return false;
  };


  


  useEffect(() => {
    if (location.pathname.toLowerCase() == '/tasks') {
      const filterType = query.get('filterType');
      const filterCompleted = query.get('filterCompleted');
      const filterPriority = query.get('filterPriority');
      const taskId = query.get('taskId');

      if (filterType) setFilterType(filterType);
      if (filterCompleted) setFilterCompleted(filterCompleted);
      if (filterPriority) setFilterPriority(parseInt(filterPriority));

      if (taskId) {
        const task = data.tasks.find((task) => {
          return (parseInt(task.id) == parseInt(taskId));
        });

        if (task) {
          setShowTaskDetails(task);
        }
      } else {
        setShowTaskDetails(false);
      }
    }
  }, [location]);

  useEffect(() => {
    if (showTaskDetails) {
      if (isTaskUnread(showTaskDetails.id)) {
        data.deleteUserUnreadTasks(showTaskDetails.id);
      }

      data.clearUserUnreadMessages('task', showTaskDetails.id);
    }
  }, [showTaskDetails]);


  return (
    <Fragment>
      <div className="flex flex-col h-full">
        <div className="flex border-b border-gray-200 px-4 py-2 items-center">
          <div>
            <button type="button" className="bg-primary text-white px-4 rounded py-1" onClick={() => { setShowSidebar(!showSidebar); }}>
              <FontAwesomeIcon icon={['fas', 'bars']} />
            </button>
          </div>

          <Menu as="div" className="relative inline-block text-left z-10">
            <div>
              <Menu.Button className="items-center justify-center w-full px-4 py-2 text-gray-700 hover:bg-gray-50 focus:outline-none">
                {selectedFilterType().label}

                <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" className="ml-2" />
              </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {filterTypeOptions.map((filterTypeOption, index) => (
                    <Menu.Item key={index}>
                      <button type="button" className="block px-4 py-2 w-full text-left hover:bg-gray-50" onClick={() => setFilterType(filterTypeOption.value)}>
                        {filterTypeOption.label}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu as="div" className="relative inline-block text-left z-10">
            <div>
              <Menu.Button className="items-center justify-center w-full px-4 py-2 text-gray-700 hover:bg-gray-50 focus:outline-none">
                {selectedFilterCompleted().label}

                <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" className="ml-2" />
              </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {filterCompletedOptions.map((filterCompletedOption, index) => (
                    <Menu.Item key={index}>
                      <button type="button" className="block px-4 py-2 w-full text-left hover:bg-gray-50" onClick={() => setFilterCompleted(filterCompletedOption.value)}>
                        {filterCompletedOption.label}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu as="div" className="relative inline-block text-left z-10">
            <div>
              <Menu.Button className="items-center justify-center w-full px-4 py-2 text-gray-700 hover:bg-gray-50 focus:outline-none">
                <FontAwesomeIcon icon={['fas', 'square']} className="mr-2" fixedWidth style={{ color: selectedFilterPriority()?.color }} /> {selectedFilterPriority()?.label}
                <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" className="ml-2" />
              </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {filterPriorityOptions.map((filterPriorityOption, index) => (
                    <Menu.Item key={index}>
                      <button type="button" className="block px-4 py-2 w-full text-left hover:bg-gray-50" onClick={() => setFilterPriority(filterPriorityOption.value)}>
                        <FontAwesomeIcon icon={['fas', 'square']} className="mr-2" fixedWidth style={{ color: filterPriorityOption.color }} />{filterPriorityOption.label}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu as="div" className="relative inline-block text-left z-10">
            <div>
              <Menu.Button className="items-center justify-center w-full px-4 py-2 text-gray-700 hover:bg-gray-50 focus:outline-none">
                Created by {selectedfilterAuthor()?.label}

                <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" className="ml-2" />
              </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {filterAuthorOptions.map((filterAuthor, index) => (
                    <Menu.Item key={index}>
                      <button type="button" className="block px-4 py-2 w-full text-left hover:bg-gray-50" onClick={() => setfilterAuthor(filterAuthor.value)}>
                        <div className="inline-block align-middle mr-2">
                          <UserAvatar user={filterAuthor.user} className="w-6 h-6 rounded-full" />
                        </div>

                        <span className="inline-block align-middle">{filterAuthor.label}</span>
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Menu as="div" className="relative inline-block text-left z-10">
            <div>
              <Menu.Button className="items-center justify-center w-full px-4 py-2 text-gray-700 hover:bg-gray-50 focus:outline-none">
                {selectedFilterDueDate()?.label}

                <FontAwesomeIcon icon={['far', 'chevron-down']} size="xs" className="ml-2" />
              </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {filterDueDateOptions.map((filterDueDateOption, index) => (
                    <Menu.Item key={index}>
                      <button type="button" className="block px-4 py-2 w-full text-left hover:bg-gray-50" onClick={() => { setFilterDueDateStart(filterDueDateOption.dateStart); setFilterDueDateEnd(filterDueDateOption.dateEnd); }}>
                        {filterDueDateOption.label}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <div>
            <button type="button" className="px-4 py-2 hover:bg-gray-50" onClick={() => resetFilters()}>
              <FontAwesomeIcon icon={['far', 'filter-slash']} className="mr-2" />Reset Filters
            </button>
          </div>

          <div className="flex-grow">

          </div>

          <div>
            <input type="text" className="rounded px-3 py-1 border border-gray-300 shadow-sm" placeholder="Search Tasks..." value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} />
          </div>
        </div>

        <div className="flex flex-grow">
          <div className={classNames('flex-shrink-0 overflow-hidden transition-all', { 'w-[280px] opacity-100' : showSidebar, 'w-0 opacity-0' : !showSidebar })}>
            <div className="w-[280px] p-5">
              <div className="grid grid-cols-2 mt-2">
                <div>
                  <button type="button" className={classNames('py-2 block w-full rounded-l border-t border-l border-b border-gray-200 text-sm font-bold', { 'bg-gray-50 shadow-inner': viewType == 'list' })} onClick={() => setViewType('list')}>
                    <FontAwesomeIcon icon={['far', 'list-check']} size="lg" className="mr-2" /> List
                  </button>
                </div>

                <div>
                  <button type="button" className={classNames('py-2 block w-full rounded-r border border-gray-200 text-sm font-bold', { 'bg-gray-50 shadow-inner': viewType == 'calendar' })} onClick={() => setViewType('calendar')}>
                    <FontAwesomeIcon icon={['far', 'calendar-range']} size="lg" className="mr-2" /> Calendar
                  </button>
                </div>
              </div>
              
              {getQuickLinks().map((quickLinksSection) => (
                <>
                  <div className="text-sm text-gray-500 font-bold mt-5">{quickLinksSection.label}</div>

                  <div className="mt-2">
                    {quickLinksSection.links.map((link, index) => (
                      <button type="button" key={index} className={classNames('p-2 w-full text-left flex items-center', { 'text-gray-700 hover:text-gray-900 hover:bg-gray-50': link.key != selectedQuickLink.key, 'bg-gray-200 hover:bg-gray-200 text-gray-900 rounded': link.key == selectedQuickLink?.key })} onClick={() => selectQuickLink(link)}>
                        <div>
                          <FontAwesomeIcon icon={link.icon} className="mr-2" size="lg" fixedWidth />
                        </div>

                        <div className="flex-1">
                          {link.label}
                        </div>

                        {(link.number > 0) && (
                          <div className="w-7 h-7 rounded-full bg-primary text-white text-xs font-bold text-center pt-1 border-2 border-white shadow">
                            {link.number}
                          </div>
                        )}
                      </button>
                    ))}
                  </div>
                </>
              ))}

              <div className="mt-6">
                <button type="button" className="bg-primary text-white block w-full p-2 rounded" onClick={() => setShowNewTask(true)}>
                  <FontAwesomeIcon icon={['far', 'plus']} fixedWidth className="mr-2" />Create New Task
                </button>
              </div>
            </div>
          </div>

          <div className="flex-grow border-l border-gray-200 dark:border-gray-900">
            {viewType == 'list' && (
              <>
                {(filteredTasks().length == 0) && (
                  <div className="h-full flex items-center justify-center">
                    <div className="p-10 text-center">
                      <div>
                        <FontAwesomeIcon icon={['fad', 'clipboard-list-check']} size="10x" className="text-gray-300" />
                      </div>
                      <div className="text-3xl text-gray-600 mt-4">
                        No Tasks Found
                      </div>
                      <div className="mt-2 text-xl text-primary">
                        Try adjusting your search filters.
                      </div>
                    </div>
                  </div>                  
                )}

                {(filteredTasks().length) > 0 && (
                  <div className="p-5 divide-y-2 divide-gray-100">
                    {filteredTasks().map((task) => (
                      <div key={task.id} className="bg-white overflow-hidden p-5 hover:bg-gray-50 cursor-pointer relative" onClick={() => setShowTaskDetails(task)}>
                        <div className="flex gap-5">
                          <div>
                            <button type="button" className={classNames('w-6 h-6 border-2 rounded', { 'bg-white border-gray-200' : !task.completed }, { 'bg-green-400 border-green-500': task.completed })} onClick={(e) => { e.stopPropagation(); updateTask(task.id, { completed: !task.completed }) }}>
                              {task.completed ? <FontAwesomeIcon icon={['fas', 'check']} className="text-white mb-0.5"/> : <>&nbsp;</>}
                            </button>
                          </div>

                          <div>
                            <div>{task.title}</div>
                            <div className="flex gap-2 mt-4 items-center">
                              <div>
                                <Tooltip title={`${task.user?.firstName} ${task.user?.lastName}`} className="z-0">
                                  <UserAvatar className="w-8 h-8 rounded-full border-2 border-white shadow" user={task.user} />
                                </Tooltip>
                              </div>

                              <div>
                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-gray-300" />
                              </div>

                              <div>
                                <div className="flex gap-1">
                                  {task.taskUsers.map((taskUser) => (
                                    <div key={taskUser.id}>
                                      <Tooltip title={`${taskUser.user?.firstName} ${taskUser.user?.lastName}`} className="z-0">
                                        <UserAvatar className="w-8 h-8 rounded-full border-2 border-white shadow" user={taskUser.user} />
                                      </Tooltip>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="pl-8">
                                <span className="px-3 py-1 font-black text-xs rounded bg-gray-50 border-b-2 text-gray-500 shadow uppercase" style={{ borderColor: task.priority?.color }}>{task.priority?.label}</span>
                              </div>

                              <div className="pl-8">
                                <FontAwesomeIcon icon={['fad', 'calendar']} className="text-gray-400 mr-2" />
                                <span className="text-gray-500">{moment.utc(task.dueDate).local().format('MMM Do, YYYY')}</span>
                              </div>
                              
                              {task.messageCount > 0 && (
                                <div className="pl-8">
                                  <FontAwesomeIcon icon={['fad', 'messages']} className={classNames('text-gray-400 mr-2', { 'text-primary animate-bounce' : getUnreadMessageCount(task.id) })} />
                                  <span className="text-gray-500">{task.messageCount}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {isTaskUnread(task.id) && (
                          <div className="bg-primary absolute origin-center right-0 top-4 w-72 text-white text-center text-sm font-black shadow border-2 border-white" style={{ transform: 'translateX(40%) rotate(45deg)' }}>NEW</div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            {viewType == 'table' && (
              <div className="p-5">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="p-3 text-left hover:bg-gray-100" onClick={() => applySort('completed')}>
                        <FontAwesomeIcon icon={['far', 'check']} size="lg" /> <SortIndicator sortBy={sortBy} sortDirection={sortDirection} field="completed" />
                      </th>

                      <th className="p-3 text-left hover:bg-gray-100" onClick={() => applySort('title')}>
                        Task Title <SortIndicator sortBy={sortBy} sortDirection={sortDirection} field="title" />
                      </th>

                      <th className="p-3 text-right hover:bg-gray-100" onClick={() => applySort('author')}>
                        Author <SortIndicator sortBy={sortBy} sortDirection={sortDirection} field="author" />
                      </th>

                      <th className="p-3 text-right hover:bg-gray-100" onClick={() => applySort('users')}>
                        Assigned To <SortIndicator sortBy={sortBy} sortDirection={sortDirection} field="users" />
                      </th>

                      <th className="p-3 text-right hover:bg-gray-100" onClick={() => applySort('priority')}>
                        Priority <SortIndicator sortBy={sortBy} sortDirection={sortDirection} field="priority" />
                      </th>

                      <th className="p-3 text-right hover:bg-gray-100" onClick={() => applySort('dueDate')}>
                        Due Date <SortIndicator sortBy={sortBy} sortDirection={sortDirection} field="dueDate" />
                      </th>

                      <th className="p-3"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredTasks().map((task) => (
                      <tr key={task.id} className={classNames('bg-white group cursor-pointer hover:bg-gray-100', { 'animate__animated animate__flash' : task.id == highlightTaskId })} onClick={() => setShowTaskDetails(task)}>
                        <td className="p-3 border-b border-gray-200">
                          <button type="button" className={classNames('w-6 h-6 border-2 rounded', { 'bg-white border-gray-200' : !task.completed }, { 'bg-green-400 border-green-500': task.completed })} onClick={(e) => { e.stopPropagation(); updateTask(task.id, { completed: !task.completed }) }}>
                            {task.completed && <FontAwesomeIcon icon={['fas', 'check']} className="mb-0.5 text-white" />}
                          </button>
                        </td>

                        <td className="p-3 border-b border-gray-200">
                          <span className={classNames({ 'line-through text-gray-400': task.completed })}>{task.title}</span>
                        </td>

                        <td className="p-3 border-b border-gray-200 text-right">
                          <div className="inline-block align-middle">
                            <Tooltip title={`${task.user?.firstName} ${task.user?.lastName}`} className="z-0">
                              <UserAvatar className="w-9 h-9 rounded-full border-2 border-white shadow" user={task.user} />
                            </Tooltip>
                          </div>
                        </td>

                        <td className="p-3 border-b border-gray-200 text-right">
                          {task.taskUsers.map((taskUser) => (
                            <div className="inline-block mr-1" key={taskUser.id}>
                              <Tooltip title={`${taskUser.user?.firstName} ${taskUser.user?.lastName}`} className="z-0">
                                <UserAvatar className="w-9 h-9 rounded-full border-2 border-white shadow" user={taskUser.user} />
                              </Tooltip>
                            </div>
                          ))}
                        </td>

                        <td className="p-3 border-b border-gray-200 text-right">
                          <span className="px-3 py-1 font-black text-xs rounded bg-gray-50 border-b-2 text-gray-500 shadow uppercase" style={{ borderColor: task.priority?.color }}>{task.priority?.label}</span>
                        </td>

                        <td className="p-3 border-b border-gray-200 text-right whitespace-nowrap">
                          {task.dueDate !== null && (
                            <span>{moment.utc(task.dueDate).local().format('MMM Do, YYYY')}</span>
                          )}
                        </td>

                        <td className="p-3 border-b border-gray-200 text-right whitespace-nowrap">
                          <div className="invisible group-hover:visible">
                            {task.user?.id == auth.user.id && (
                              <button type="button" className="px-2 py-1 text-gray-500 hover:text-gray-800 hover:bg-gray-50 rounded" onClick={(e) => { e.stopPropagation(); setShowDeleteTask(task) }}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                              </button>
                            )}

                            <button type="button" className="px-2 py-1 text-gray-500 hover:text-gray-800 hover:bg-gray-50 rounded" onClick={(e) => { e.stopPropagation(); setShowTaskDetails(task) }}>
                              <FontAwesomeIcon icon={['far', 'edit']} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}

                    {filteredTasks().length == 0 && (
                      <tr>
                        <td className="p-3 bg-white border-b border-gray-200" colSpan="7">No tasks found. Try adjusting or resetting your search filters.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}


            {viewType == 'calendar' && (
              <div className="p-4">
                <FullCalendar viewClassNames="bg-white" datesSet={calendarChangeDate} aspectRatio={2} events={calendarEvents()} eventClick={calendarEventClicked} plugins={[dayGridPlugin]} initialView={initialView} />
              </div>
            )}
          </div>
        </div>
      </div>


      <NewTask show={showNewTask} setShow={setShowNewTask} />
      <TaskDetails show={showTaskDetails} setShow={setShowTaskDetails} task={selectedTaskDetails()} />
      <DeleteTask show={showDeleteTask} setShow={setShowDeleteTask} task={showDeleteTask} />
    </Fragment>
  );
};


export default ViewTasks;