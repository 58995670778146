import { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useApi } from 'hooks/useApi';

import Modal from 'components/modal';
import EmojiPicker from 'components/emoji-picker';

const EditMessage = (props) => {
  const api = useApi();

  const [message, setMessage] = useState('');
  const [messageId, setMessageId] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);


  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'
  ];


  const editMessage = () => {
    if (message) {
      const request = {
        message: message
      };

      api.put(`message/${messageId}`, request).then((response) => {
        props.setShow(false);
      });
    }
  };


  const emojiSelected = (emoji) => {
    setMessage(message + emoji.emoji);

    setShowEmojiPicker(false);
  };


  useEffect(() => {
    setMessage(props.message.message);
    setMessageId(props.message.id);
  }, [props.message, props.show]);


  return (
    <Fragment>
      <Modal show={(props.show) ? true : false} setShow={props.setShow}>
        <div className="p-4">
          <div className="flex items-center">
            <div className="flex-grow">
              <span className="text-xl">Edit Message</span>
            </div>

            <div>
              <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
              </button>
            </div>
          </div>
        </div>

        <div className="pl-4 pb-4 pr-4">
          <div className="rounded-md bg-white border border-gray-200">
            <ReactQuill theme="snow" value={message} onChange={setMessage} modules={modules} formats={formats} />

            <div className="flex pl-2 pr-2 pb-2 items-center">
              <div className="">
                <button type="button" className="text-gray-700 rounded-full w-8 h-8 hover:bg-primary hover:text-white" onClick={() => setShowEmojiPicker(true)}>
                  <FontAwesomeIcon icon={['far', 'face-smile-beam']} />
                </button>
              </div>

              <div className="flex-grow">

              </div>

              <div className="">
                <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-white text-gray-700 border border-gray-200 mr-2" onClick={() => props.setShow(false)}>
                  Cancel
                </button>

                <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-primary text-white" onClick={() => editMessage()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <EmojiPicker show={showEmojiPicker} setShow={setShowEmojiPicker} emojiSelected={emojiSelected}></EmojiPicker>
      </Modal>
    </Fragment>
  );
};


export default EditMessage;