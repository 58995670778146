import { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/modal';
import emojiData from 'unicode-emoji-json/data-by-group.json';

import classNames from 'classnames';

const EmojiPicker = (props) => {
    const emojiGroups = [
        {
            name : 'Smileys & Emotion',
            icon: ['far', 'face-smile-beam']
        },
        {
            name : 'People & Body',
            icon: ['far', 'people']
        },
        {
            name : 'Animals & Nature',
            icon: ['far', 'leaf']
        },
        {
            name : 'Food & Drink',
            icon: ['far', 'burger-soda']
        },
        {
            name : 'Travel & Places',
            icon: ['far', 'plane']
        },
        {
            name : 'Activities',
            icon: ['far', 'football']
        },
        {
            name : 'Objects',
            icon: ['far', 'lightbulb']
        },
        {
            name : 'Symbols',
            icon: ['far', 'peace']
        },
        {
            name : 'Flags',
            icon: ['far', 'flag-usa']
        }
    ];

    const [activeGroup, setActiveGroup] = useState(emojiGroups[0]);
    const [hoveredEmoji, setHoveredEmoji] = useState(false);

    const maxUnicodeVersion = 13;


    const capitalizeWords = (words) => {
        var separateWord = words.toLowerCase().split(' ');

        for (var i = 0; i < separateWord.length; i++) {
           separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
           separateWord[i].substring(1);
        }

        return separateWord.join(' ');
    }


    useEffect(() => {
        if (props.show)
        {
            setActiveGroup(emojiGroups[0]);
            setHoveredEmoji(false);
        }
    }, [props.show]);


    return (
        <Fragment>
            <Modal show={props.show ? true : false} setShow={props.setShow}>
                <div className="p-4">
                    <div className="flex items-center">
                        <div className="flex-grow">
                            <span className="text-xl">Choose an Emoji</span>
                        </div>

                        <div>
                            <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="pb-6 pr-6 pl-6">
                    <div className="grid grid-flow-col border-b border-gray-300">
                        {emojiGroups.map((emojiGroup) => (
                            <div key={emojiGroup.name}>
                                <button className={classNames('text-center block w-full py-2 text-gray-600 hover:bg-gray-50 border-b-4 border-transparent', { 'border-primary text-gray-800 bg-gray-50' : activeGroup.name == emojiGroup.name })} onClick={() => setActiveGroup(emojiGroup)}>
                                    <FontAwesomeIcon icon={emojiGroup.icon} fixedWidth></FontAwesomeIcon>
                                </button>
                            </div>
                        ))}
                    </div>

                    {activeGroup && (
                        <Fragment>
                            <div className="py-2 text-sm font-bold">{activeGroup.name}</div>

                            <div className="grid grid-cols-9 overflow-y-auto p-2 rounded border border-gray-200 bg-gray-50" style={{ maxHeight : '400px' }}>
                                {emojiData[activeGroup.name].map((emoji, index) => (
                                    <Fragment key={index}>
                                        {emoji.unicode_version <= maxUnicodeVersion && (
                                            <div>
                                                <button className="w-full block text-center py-2 text-2xl rounded-md hover:bg-gray-200" onClick={() => props.emojiSelected(emoji)} onMouseEnter={() => setHoveredEmoji(emoji)} onMouseLeave={() => setHoveredEmoji(false)}>{emoji.emoji}</button>
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                            </div>

                            <div className="py-1 h-12 mt-2">
                                {hoveredEmoji && (
                                    <Fragment>
                                        <span className="text-4xl inline-block align-middle">{hoveredEmoji.emoji}</span>

                                        <span className="inline-block align-middle ml-2">{capitalizeWords(hoveredEmoji.name)}</span>
                                    </Fragment>
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>
            </Modal>
        </Fragment>
    );
};


export default EmojiPicker;