import { FileIcon, defaultStyles } from 'react-file-icon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from 'moment';
import parse from 'html-react-parser';
import classNames from 'classnames';

import { useAuth } from 'hooks/useAuth';

import { getFileExtension, formatBytes, isImage } from 'helpers/files';

import config from 'config';

import Tooltip from 'components/tooltip';
import UserAvatar from 'components/user-avatar';

const Message = ({ message, highlightMessageId, setImagePreview, addReaction, deleteReaction, setShowEmojiPicker, setDeleteMessage, setEditMessage }) => {
  const auth = useAuth();

  return (
    <div key={message.id}>
      <div className={classNames('flex group hover:bg-gray-50 p-3', { 'border-2 rounded border-primary shadow': message.id == highlightMessageId })}>
        <div className="pt-1.5 flex-shrink-0">
          <UserAvatar user={message.user} showStatus={false} className="w-10 h-10 rounded border-2 border-white shadow" />
        </div>

        <div className="pl-2 flex-1 min-w-0">
          <div>
            <span className="font-semibold">{message.user.firstName} {message.user.lastName}</span>
            <span className="text-sm ml-4 text-gray-500">{moment(message.createdAt).format('h:mm A')}</span>

            {message.edited && (
              <span className="ml-4 text-gray-400 text-sm">(edited)</span>
            )}
          </div>

          {(message.type.toLowerCase() == 'message') && (
            <div className="max-w-[50vw] break-words">
              {parse(message.message || '')}
            </div>
          )}

          {(message.type.toLowerCase() == 'file' && message.files) && (
            <div className="space-y-5 mt-3">
              {message.files.map((file, index) => (
                <div className="flex items-center" key={index}>
                  {isImage(file.mimeType) && (
                    <div>
                      <img src={config.apiUrl + 'file/' + file.accountId + '/' + file.name} style={{ maxWidth: '200px', maxHeight: '200px' }} className="cursor-pointer" onClick={() => setImagePreview(file)} />
                    </div>
                  )}

                  {!isImage(file.mimeType) && (
                    <div className="w-12">
                      <FileIcon extension={getFileExtension(file.name)} {...defaultStyles[getFileExtension(file.name)]} />
                    </div>
                  )}

                  <div className="pl-5">
                    <div className="font-bold">{file.name}</div>
                    <div className="text-gray-600 text-sm">{formatBytes(file.size)}</div>
                  </div>

                  <div className="flex-grow pl-5">
                    <a href={config.apiUrl + 'file/' + file.accountId + '/' + file.name} target="_blank" className="hidden group-hover:inline-block text-gray-500 hover:text-primary decoration-0" download>
                      <FontAwesomeIcon icon={['far', 'cloud-arrow-down']} size="lg" className="mr-2" /> Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}

          {(message.reactions && message.reactions.length > 0) && (
            <div className="mt-1">
              {message.reactions.map((reaction) => (
                <Tooltip className="inline-block" title={`${reaction.user.firstName} ${reaction.user.lastName}`} key={reaction.id}>
                  {(reaction.user?.id === auth.user.id) && (
                    <button key={reaction.id} className="py-1 px-2 bg-white border border-gray-200 rounded-full mr-2 text-sm shadow-sm hover:bg-gray-100" onClick={() => deleteReaction(message, reaction)}>
                      {reaction.emoji}
                    </button>
                  )}

                  {(reaction.user?.id !== auth.user.id) && (
                    <span key={reaction.id} className="py-1 px-2 mr-2 text-sm">
                      {reaction.emoji}
                    </span>
                  )}
                </Tooltip>
              ))}

              <button className="py-1 px-2 bg-gray-100 rounded-full mr-2 text-sm hover:bg-gray-200" onClick={() => setShowEmojiPicker(message)}>
                <FontAwesomeIcon icon={['fas', 'plus']}></FontAwesomeIcon>
              </button>
            </div>
          )}
        </div>

        <div className="flex-shrink-0">
          <div className="bg-white rounded-md shadow-sm p-1 invisible group-hover:visible">
            <button type="button" className="py-0.5 px-1 text-yellow-500 rounded hover:bg-gray-50 hover:text-primary" onClick={() => addReaction(message, '✅')}>
              ✅
            </button>

            <button type="button" className="py-0.5 px-1 text-yellow-500 rounded hover:bg-gray-50 hover:text-primary" onClick={() => addReaction(message, '👍')}>
              👍
            </button>

            <button type="button" className="py-0.5 px-1 rounded hover:bg-gray-50 hover:text-primary" onClick={() => setShowEmojiPicker(message)}>
              <FontAwesomeIcon icon={['fas', 'ellipsis']}></FontAwesomeIcon>
            </button>

            {(message.user.id == auth.user.id) && (
              <>
                <div className="h-full bg-gray-200 inline-block mx-1" style={{ width: '1px' }}>&nbsp;</div>

                <button type="button" className="py-0.5 px-2 text-gray-700 rounded hover:bg-gray-50 hover:text-primary" onClick={() => setDeleteMessage(message)}>
                  <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
                </button>

                {(message.type.toLowerCase() == 'message') && (
                  <button type="button" className="py-0.5 px-2 text-gray-700 rounded hover:bg-gray-50 hover:text-primary" onClick={() => setEditMessage(message)}>
                    <FontAwesomeIcon icon={['far', 'edit']} size="sm" />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};


export default Message;