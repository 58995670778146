import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Modal from 'components/modal';
import config from 'config';


const ImagePreview = ({ show, setShow, file }) => {

    return (
        <Fragment>
            <Modal show={(show) ? true : false} setShow={setShow} className="sm:w-auto overflow-visible rounded-none">
                <button type="button" onClick={() => setShow(false)} className="w-10 h-10 bg-white text-gray-700 hover:text-primary absolute rounded-full -right-5 -top-5 shadow-lg">
                    <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                </button>

                {file && (
                    <img className="inline-block" src={config.apiUrl + 'file/' + file.accountId + '/' + file.name} style={{ maxWidth : '75vw', maxHeight : '75vh' }} />
                )}
            </Modal>
        </Fragment>
    );
};


export default ImagePreview;