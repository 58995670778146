import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { useDebounce } from 'hooks/useDebounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserAvatar from 'components/user-avatar';
import ChannelIcon from 'components/channel-icon';

import moment from 'moment';
import classNames from 'classnames';
import parse from 'html-react-parser';

const ViewSearch = () => {
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();

  const emptyResults = {
    messages: [],
    tasks: [],
    taskComments: []
  };

  const [activeTab, setActiveTab] = useState('gabs');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(emptyResults);

  const debouncedQuery = useDebounce(query, 500);

  const highlight = (message, query) => {
    const regex = new RegExp(query, 'gi');

    message = message.replace(/(<mark class="bg-rose-300">|<\/mark>)/gim, '');

    return message.replace(regex, '<mark class="bg-rose-300">$&</mark>');
  };

  const tabs = () => {
    return [
      {
        key: 'gabs',
        label: 'Gabs',
        icon: results.messages.length
      },
      {
        key: 'tasks',
        label: 'Tasks',
        icon: results.tasks.length
      },
      {
        key: 'taskComments',
        label: 'Task Comments',
        icon: results.taskComments.length
      }
    ];
  };

  const search = () => {
    if (debouncedQuery) setLoading(true);
    setResults(emptyResults);

    if (debouncedQuery) {
      const request = {
        query
      }

      api.post('search', request).then((response) => {
        if (response.data) {
          setResults(response.data);
        }

        setLoading(false);
      });
    }
  };

  const messageClicked = (message) => {
    const request = {
      moduleName: message.moduleName,
      moduleId: message.moduleId,
      messageId: message.id,
      startDate: moment(message.createdAt).startOf('day').toISOString(),
      endDate: moment(message.createdAt).startOf('day').toISOString()
    };
    
    const queryString = new URLSearchParams(request).toString();

    history.push(`/chat?${queryString}`);
  };

  const taskClicked = (task) => {
    const request = {
      filterType : (task.userId == auth.user.id) ? 'created' : 'assigned',
      filterCompleted: (task.completed) ? 'completed' : 'incomplete',
      filterPriority: task.priority.value,
      taskId: task.id
    };

    const queryString = new URLSearchParams(request).toString();

    history.push(`tasks?${queryString}`);
  };

  useEffect(() => {
    search();
  }, [debouncedQuery]);


  return (
    <div>
      <div className="pt-16 bg-gray-100">
        <div className="max-w-3xl mx-auto">
          <div className="flex items-center rounded border border-gray-300 bg-white shadow-sm">
            <div>
              {!loading && (
                <FontAwesomeIcon icon={['fad', 'search']} className="px-3 text-gray-400" size="lg" />
              )}

              {loading && (
                <FontAwesomeIcon icon={['fad', 'spinner']} spin className="px-3 text-gray-400" size="lg" />
              )}
            </div>

            <div className="flex-1">
              <input type="text" className="w-full border-0 outline-0 focus:outline-0 focus:ring-0 text-xl pl-0 py-3 pr-2 bg-transparent" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search gabs, tasks and comments..." />
            </div>

            {query && (
              <div>
                <button className="px-3 py-2 text-gray-400 hover:text-gray-600" onClick={(e) => setQuery('')}>
                  <FontAwesomeIcon icon={['fad', 'times']} size="lg" />
                </button>
              </div>
            )}
          </div>

          <div className="mt-8 grid grid-cols-3 items-center">
            {tabs().map((tab) => (
              <div key={tab.key}>
                <button type="button" onClick={(e) => setActiveTab(tab.key)} className={classNames('block w-full py-2 text-gray-600 hover:text-gray-900 border-b-4 border-transparent', { 'border-b-gray-200': tab.key != activeTab, 'border-b-primary text-gray-900' : tab.key == activeTab })}>
                  <span className="inline-block align-middle font-semibold">{tab.label}</span> {(tab.icon > 0) && (<span className="bg-gray-200 inline-block align-middle rounded-full py-1 px-4 text-xs font-semibold text-primary ml-2 pt-1 shadow-inner">{tab.icon}</span>)}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-10 max-w-3xl mx-auto">
        <div className={classNames({ 'hidden' : activeTab !== 'gabs', 'visible' : activeTab == 'gabs' })}>
          {results.messages.length > 0 && (
            <div className="divide-y divide-gray-200 rounded border border-gray-200">
              {results.messages.map((message) => (
                <div className="hover:bg-gray-50 p-5 cursor-pointer" onClick={() => messageClicked(message)} key={message.id}>
                  <div className="flex gap-x-4">
                    <div className="pt-1.5 flex-shrink-0">
                      <UserAvatar user={message.user} showStatus={false} className="w-10 h-10 rounded-full border-2 border-white shadow" />
                    </div>

                    <div className="pl-2 flex-grow">
                      <div>
                        <span className="font-semibold">{message.user.firstName} {message.user.lastName}</span>
                        <span className="text-sm ml-4 text-gray-500">{moment(message.createdAt).format('MMM Do, YYYY h:mm A')}</span>

                        {message.edited && (
                          <span className="ml-4 text-gray-400 text-sm">(edited)</span>
                        )}
                      </div>

                      {(message.type.toLowerCase() == 'message') && (
                        <div>
                          {parse(message.message || '')}
                        </div>
                      )}

                      {(message.moduleName == 'channel') && (
                        <div className="pt-1.5 flex items-center gap-x-2">
                          <div>
                            <ChannelIcon name={message.channel?.title} className="w-8 h-8 rounded" />
                          </div>
                          
                          <div className="flex-1 font-bold">
                            {message.channel?.title}
                          </div>
                        </div>
                      )}

                      {(message.moduleName == 'direct') && (
                        <div className="pt-1.5 flex items-center gap-x-2">
                          <div>
                            
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={classNames({ 'hidden' : activeTab !== 'tasks', 'visible' : activeTab == 'tasks' })}>
          {results.tasks.length > 0 && (
            <div className="divide-y divide-gray-200 rounded border border-gray-200">
              {results.tasks.map((task) => (
                <div className="hover:bg-gray-50 p-5 cursor-pointer" onClick={() => taskClicked(task)} key={task.id}>
                  <div className="flex gap-x-4">
                    <div>
                      <div className={classNames('w-6 h-6 border-2 rounded inline-block text-center', { 'bg-white border-gray-200' : !task.completed }, { 'bg-green-400 border-green-500': task.completed })}>
                        {task.completed && <FontAwesomeIcon icon={['fas', 'check']} className="mb-0.5 text-white" />}
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center gap-x-2">
                        <div>
                          <UserAvatar className="w-8 h-8 rounded-full border-2 border-white shadow" user={task.user} />
                        </div>
                        <div className="font-bold">
                          {task.user.firstName} {task.user.lastName}
                        </div>
                      </div>

                      {task.dueDate !== null && (
                        <div className="mt-2 text-sm text-gray-400">
                          <FontAwesomeIcon icon={['fad', 'calendar']} className="mr-2" /><span>{moment.utc(task.dueDate).local().format('MMM Do, YYYY')}</span>
                        </div>
                      )}
                    </div>

                    <div className="flex-1">
                      {task.title}
                    </div>

                    <div>
                      <span className="px-3 py-1 border-b-2 text-gray-500 shadow bg-gray-50 font-black text-xs rounded uppercase" style={{ borderColor: task.priority?.color }}>{task.priority?.label}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={classNames({ 'hidden' : activeTab !== 'taskComments', 'visible' : activeTab == 'taskComments' })}>
          {results.taskComments.length > 0 && (
            <div className="divide-y divide-gray-200 rounded border border-gray-200">
              {results.taskComments.map((message) => (
                <div className="hover:bg-gray-50 p-5 cursor-pointer" onClick={() => taskClicked(message.task)} key={message.id}>
                  <div className="flex gap-x-4">
                    <div className="pt-1.5 flex-shrink-0">
                      <UserAvatar user={message.user} showStatus={false} className="w-10 h-10 rounded-full border-2 border-white shadow" />
                    </div>

                    <div className="pl-2 flex-grow">
                      <div>
                        <span className="font-semibold">{message.user.firstName} {message.user.lastName}</span>
                        <span className="text-sm ml-4 text-gray-500">{moment(message.createdAt).format('MMM Do, YYYY h:mm A')}</span>

                        {message.edited && (
                          <span className="ml-4 text-gray-400 text-sm">(edited)</span>
                        )}
                      </div>

                      {(message.type.toLowerCase() == 'message') && (
                        <div>
                          {parse(message.message || '')}
                        </div>
                      )}

                      {(message.moduleName == 'task') && (
                        <>
                          <div className="text-sm text-gray-300">
                            Posted to:
                          </div>
                          <div className="py-2 px-4 flex items-center gap-x-4 rounded bg-white mt-2">
                            <div>
                              <div className={classNames('w-6 h-6 border-2 rounded inline-block text-center', { 'bg-white border-gray-200' : !message.task.completed }, { 'bg-green-400 border-green-500': message.task.completed })}>
                                {message.task.completed && <FontAwesomeIcon icon={['fas', 'check']} className="mb-0.5 text-white" />}
                              </div>
                            </div>

                            <div>
                              <div className="flex items-center gap-x-2">
                                <div>
                                  <UserAvatar className="w-8 h-8 rounded-full border-2 border-white shadow" user={message.task.user} />
                                </div>
                                <div className="font-bold">
                                  {message.task.user.firstName} {message.task.user.lastName}
                                </div>
                              </div>

                              {message.task.dueDate !== null && (
                                <div className="mt-2 text-sm text-gray-400">
                                  <FontAwesomeIcon icon={['fad', 'calendar']} className="mr-2" /><span>{moment.utc(message.task.dueDate).local().format('MMM Do, YYYY')}</span>
                                </div>
                              )}
                            </div>

                            <div className="flex-1">
                              {message.task.title}
                            </div>

                            <div>
                              <span className="px-3 py-1 border-b-2 text-gray-500 shadow bg-gray-50 font-black text-xs rounded uppercase" style={{ borderColor: message.task.priority?.color }}>{message.task.priority?.label}</span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default ViewSearch;