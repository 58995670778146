import { useState, useEffect, useRef, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from 'components/modal';
import UserSelect from 'components/user-select';

import { useAuth } from 'hooks/useAuth';
import { useData } from 'hooks/useData';


const NewChannel = (props) => {
    const auth = useAuth();
    const data = useData();

    const [title, setTitle] = useState('');
    const [users, setUsers] = useState([auth.user.id]);


    const saveChannel = (event) => {
        event.preventDefault();
        
        if (users.length)
        {
            const channel = {
                title: title,
                users: users
            };
            
            data.createChannel(channel).then((response) => {
                props.setShow(false);
            });
        }
    };


    useEffect(() => {
        if (!props.show)
        {
            setTitle('');
            setUsers([auth.user.id]);
        }
    }, [props.show]);


    return (
        <Fragment>
            <Modal show={props.show} setShow={props.setShow}>
                <div className="p-4">
                    <div className="flex items-center">
                        <div className="pr-4">
                            <FontAwesomeIcon className="text-primary inline-block" icon={['fal', 'plus-circle']} size="2x" />
                        </div>

                        <div className="flex-grow">
                            <span className="text-xl">New Gab Session</span>
                        </div>

                        <div>
                            <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="pl-6 pb-6 pr-6 pt-2">
                    <form className="space-y-6" onSubmit={saveChannel}>
                        <div>
                            <input type="text" id="text" className="form-control bg-gray-50 border-0 mt-1 text-lg focus:bg-gray-100" placeholder="Enter a title for this new gab session..." onChange={(e) => setTitle(e.target.value)} required />
                        </div>

                        <div>
                            <UserSelect users={users} setUsers={setUsers} message="Select participating team members..." />
                        </div>

                        <div className="text-center">
                            <button type="submit" className="bg-primary text-white px-5 py-2 rounded-full font-bold disabled:text-gray-400 disabled:bg-gray-100" disabled={(title.trim() == '' || users.length == 0)}>Create Gab Session</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Fragment>
    );
};


export default NewChannel;