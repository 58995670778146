import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApi } from 'hooks/useApi';
import { useData } from 'hooks/useData';

import UserAvatar from 'components/user-avatar';

import classNames from 'classnames';

const UserSelect = ({ users = [], setUsers, hiddenUsers = [], message = 'Select team members...' }) => {
    const api = useApi();
    const data = useData();

    const [selectedUserIds, setSelectedUsersIds] = useState(users);

    const selectedUsers = () => {
        return data.users.filter((user) => {
            return (selectedUserIds.indexOf(user.id) >= 0);
        });
    };


    const toggleSelectedUserId = (userId) => {
        if (selectedUserIds.indexOf(userId) >= 0)
        {
            setSelectedUsersIds(selectedUserIds.filter(selectedUserId => selectedUserId != userId));
        }
        else
        {
            setSelectedUsersIds([...selectedUserIds, userId]);
        }
    };


    const filteredUsers = () => {
        return data.users.filter((user) => {
            if (hiddenUsers.length)
            {
                return (hiddenUsers.indexOf(user.id) >= 0) ? false : true;
            }
            else
            {
                return true;
            }
        });
    };


    useEffect(() => {
        if (setUsers)
        {
            setUsers(selectedUserIds);
        }
    }, [selectedUserIds]);


    return (
        <div>
            <div className="pb-4 text-gray-700">
                {(selectedUsers().length == 0) && (
                    <div>
                        {message}
                    </div>
                )}

                {(selectedUsers().length > 0) && (
                    <div>{selectedUsers().length} team member{selectedUsers().length > 1 ? 's' : ''} selected</div>
                )}
            </div>

            <div className="space-y-1 max-h-96 overflow-y-auto">
                {filteredUsers().map((user) => (
                    <button type="button" className={classNames('flex w-full p-2 items-center text-left rounded hover:bg-gray-50', { 'bg-gray-100' : selectedUserIds.indexOf(user.id) >= 0 } )} key={user.id} onClick={() => toggleSelectedUserId(user.id)}>
                        <div className="relative">
                            <UserAvatar user={user} className="w-10 h-10 rounded-full border-2 border-white shadow" />

                            <span className={classNames('absolute w-5 h-5 -bottom-1 -right-1 rounded-full bg-green-600 text-white text-center text-xs border-2 border-white transition-all', { 'opacity-100 scale-100' : selectedUserIds.indexOf(user.id) >= 0, 'opacity-0 scale-0' : selectedUserIds.indexOf(user.id) == -1 })}>
                                <FontAwesomeIcon icon={['fas', 'check']} size="xs" />
                            </span>
                        </div>

                        <div className="flex-1 pl-4">
                            <span className="text-gray-600 text-lg">{user.firstName} {user.lastName}</span>
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};


export default UserSelect;