import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import ViewLogin from 'views/guest/login';
import ViewResetPassword from 'views/guest/resetPassword';

const LayoutGuest = () => {
    return (
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>

        <Route path="/login">
          <ViewLogin />
        </Route>

        <Route path="/resetPassword/:forgotPasswordKey">
          <ViewResetPassword />
        </Route>

        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    );
};


export default LayoutGuest;