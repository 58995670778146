export function getFileExtension(filename) {
    if (filename) {
        return filename.split('.').pop();
    }
    
    return '';
};


export function formatBytes(bytes,decimals) {
    if(bytes == 0) return '0 Bytes';

    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export function isImage(mimeType) {
    if (mimeType) {
        const mimeParts = mimeType.split('/');

        const imageExtensions = [
            'jpg',
            'jpeg',
            'png',
            'gif',
            'webp'
        ];

        if (mimeParts.length == 2)
        {
            if (mimeParts[0].toLowerCase() == 'image')
            {
                if (imageExtensions.indexOf(mimeParts[1].toLowerCase()) >= 0)
                {
                    return true;
                }
            }
        }
    }

    return false;
}