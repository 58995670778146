import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApi } from 'hooks/useApi';

import Modal from 'components/modal';

const DeleteChannel = (props) => {
  const api = useApi();

  const deleteChannel = () => {
    api.delete(`channel/${props.channel?.id}`).then((response) => {
      if (props.channelDeleted) {
        props.channelDeleted();
      }

      props.setShow(false);
    });
  };


  return (
    <Fragment>
      <Modal show={(props.show) ? true : false} setShow={props.setShow}>
        <div className="p-4">
          <div className="flex items-center">
            <div className="flex-grow">
              <span className="text-xl">Delete Channel</span>
            </div>

            <div>
              <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
              </button>
            </div>
          </div>
        </div>

        <div className="pl-4 pb-4 pr-4">
          <div>Are you sure you want to delete this gab session? This cannot be undone.</div>

          <div className="mt-2 py-2 px-3 rounded border border-gray-200 text-xl">
            # {props.channel?.title}
          </div>

          <div className="flex mt-2 items-center">
            <div className="flex-grow">

            </div>

            <div className="mt-2">
              <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-white text-gray-700 border border-gray-200 mr-2" onClick={() => props.setShow(false)}>
                Cancel
              </button>

              <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-primary text-white" onClick={() => deleteChannel()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};


export default DeleteChannel;