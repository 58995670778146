import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useAuth } from 'hooks/useAuth';
import { useApi } from 'hooks/useApi';
import { useSocket } from 'hooks/useSocket';

import EmojiPicker from 'components/emoji-picker';
import UploadFile from 'components/upload-file';

const ComposeMessage = ({ moduleName, moduleId, title, messageSent, disabled }) => {
  const auth = useAuth();
  const api = useApi();
  const socket = useSocket();

  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const quill = useRef(null);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'
  ];


  const sendMessage = () => {
    if (message) {
      setLoading(true);

      const request = {
        message: message,
        moduleName: moduleName,
        moduleId: moduleId
      };

      api.post('message', request).then((response) => {
        const message = response.data;

        if (messageSent) {
          messageSent(message);
        }

        setTimeout(() => {
          quill.current.getEditor().focus();
        }, 100);
        
        setMessage('');
      }).catch((error) => {

      }).finally(() => {
        setLoading(false);
      });
    }
  };


  const emojiSelected = (emoji) => {
    setMessage(message + emoji.emoji);

    setShowEmojiPicker(false);
  };


  if (quill) {
    if (quill.current) {
      if (title) {
        quill.current.getEditor().root.dataset.placeholder = 'Message ' + title;
      }
      else {
        quill.current.getEditor().root.dataset.placeholder = 'Write a message...';
      }

      quill.current.getEditor().keyboard.bindings[13].unshift({
        key: 13,
        handler: (range, context) => {
          sendMessage();

          return false;
        }
      })
    }
  }

  useEffect(() => {
    setMessage('');
  }, [moduleName, moduleId]);


  if (disabled) {
    return (
      <div className="p-2">
        Sending new messages to <strong>{title}</strong> has been disabled, as it has been archived.
      </div>
    )
  }


  return (
    <>
      <div className="rounded-md bg-white border border-gray-200">
        <ReactQuill theme="snow" value={message} onChange={setMessage} modules={modules} formats={formats} ref={quill} readOnly={loading} />

        <div className="flex pl-2 pr-2 pb-2 items-center">
          <div className="">
            <button type="button" className="rounded-full text-gray-700 bg-gray-100 w-8 h-8 hover:bg-gray-200 hover:text-gray-900" onClick={() => setShowUploadFile(true)} disabled={loading}>
              <FontAwesomeIcon icon={['far', 'paperclip']} />
            </button>
          </div>

          <div className="px-2">
            <div className="h-full bg-gray-300" style={{ width: '1px' }}>&nbsp;</div>
          </div>

          <div className="">
            <button type="button" className="text-gray-700 rounded-full w-8 h-8 hover:bg-primary hover:text-white" onClick={() => setShowEmojiPicker(true)} disabled={loading}>
              <FontAwesomeIcon icon={['far', 'face-smile-beam']} />
            </button>
          </div>

          <div className="flex-grow">

          </div>

          <div className="">
            <button type="button" className="w-8 h-8 text-gray-700 hover:text-primary" onClick={() => sendMessage()} disabled={loading}>
              {!loading && (
                <FontAwesomeIcon icon={['fas', 'send']} />
              )}

              {loading && (
                <FontAwesomeIcon icon={['fad', 'spinner-third']} spin />
              )}
            </button>
          </div>
        </div>
      </div>

      <EmojiPicker show={showEmojiPicker} setShow={setShowEmojiPicker} emojiSelected={emojiSelected} />
      <UploadFile show={showUploadFile} setShow={setShowUploadFile} moduleName={moduleName} moduleId={moduleId} />
    </>
  );
};


export default ComposeMessage;