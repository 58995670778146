import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from 'components/modal';

import { useApi } from 'hooks/useApi';
import { useSocket } from 'hooks/useSocket';


const DeleteTask = ({ show, setShow, task, taskDeleted }) => {
  const socket = useSocket();
  const api = useApi();


  const deleteTask = () => {
    return new Promise((resolve, reject) => {
      api.delete(`task/${task.id}`).then((response) => {
        const task = response.data;

        if (typeof(taskDeleted) === 'function') {
          taskDeleted(task);
        }

        setShow(false);

        resolve(task);
      });
    });
  }


  return (
    <Modal show={show ? true : false} setShow={setShow} className="max-w-3xl">
      <div className="pl-4 pr-4 pt-4">
        <div className="flex items-center">
          <div className="pr-4">
            <FontAwesomeIcon className="text-primary inline-block" icon={['fal', 'trash-alt']} size="2x" />
          </div>

          <div className="flex-grow">
            <span className="text-xl">Delete Task</span>
          </div>

          <div>
            <button type="button" onClick={() => setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
              <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
            </button>
          </div>
        </div>
      </div>

      <div className="p-8">
        <div>Are you sure you want to delete this task? This cannot be undone.</div>

        <div className="border border-gray-200 rounded p-2 mt-2">
          <div>{task.title}</div>
        </div>

        <div className="flex mt-2 items-center">
          <div className="flex-grow">

          </div>

          <div className="mt-2">
            <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-white text-gray-700 border border-gray-200 mr-2" onClick={() => setShow(false)}>
              Cancel
            </button>

            <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-primary text-white" onClick={() => deleteTask()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};


export default DeleteTask;