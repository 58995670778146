import { Fragment, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { useAuth } from 'hooks/useAuth';

import Update from 'components/update';
import LayoutUser from 'layouts/user';
import LayoutGuest from 'layouts/guest';

import 'css/app.css';


function App() {
	const auth = useAuth();


	const checkNotificationsPermission = () => {
		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
		}
		
		// Let's check whether notification permissions have already been granted
		else if (Notification.permission == "granted") {
			
		}
		
		// Otherwise, we need to ask the user for permission
		else if (Notification.permission !== "denied") {
			Notification.requestPermission().then((permission) => {
				
			});
		}		
	};

	
	useEffect(() => {
		//document.body.classList.add('dark');

		checkNotificationsPermission();
	}, []);


	return (
		<Fragment>
			<Router>
				{(auth.token && auth.user) && (
					<LayoutUser />
				)}

				{(!auth.token || !auth.user) && (
					<LayoutGuest />
				)}
			</Router>

			<Toaster position="top-center" />
			<Update />
		</Fragment>
	);
}

export default App;
