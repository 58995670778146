import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';
import moment from 'moment';

import UserAvatar from 'components/user-avatar';
import Tooltip from 'components/tooltip';


const TaskCard = ({ task, taskClicked = (task) => {} }) => {
  return (
    <div key={task.id} className="flex bg-white p-5 gap-5 hover:bg-gray-50 cursor-pointer" onClick={() => { taskClicked(task); }}>
      <div>
        <button type="button" className={classNames('w-6 h-6 border-2 rounded', { 'bg-white border-gray-200' : !task.completed }, { 'bg-green-400 border-green-500': task.completed })}>
          {task.completed ? <FontAwesomeIcon icon={['fas', 'check']} className="text-white mb-0.5"/> : <>&nbsp;</>}
        </button>
      </div>

      <div>
        <div>{task.title}</div>
        <div className="flex gap-2 mt-4 items-center">
          <div>
            <Tooltip title={`${task.user?.firstName} ${task.user?.lastName}`} className="z-0">
              <UserAvatar className="w-8 h-8 rounded-full border-2 border-white shadow" user={task.user} />
            </Tooltip>
          </div>

          <div>
            <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-gray-300" />
          </div>

          <div>
            <div className="flex gap-1">
              {task.taskUsers.map((taskUser) => (
                <div key={taskUser.id}>
                  <Tooltip title={`${taskUser.user?.firstName} ${taskUser.user?.lastName}`} className="z-0">
                    <UserAvatar className="w-8 h-8 rounded-full border-2 border-white shadow" user={taskUser.user} />
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>

          <div className="pl-8">
            <span className="px-3 py-1 font-black text-xs rounded bg-gray-50 border-b-2 text-gray-500 shadow uppercase" style={{ borderColor: task.priority?.color }}>{task.priority?.label}</span>
          </div>

          <div className="pl-8">
            <FontAwesomeIcon icon={['fad', 'calendar']} className="text-gray-400 mr-2" />
            <span className="text-gray-500">{moment.utc(task.dueDate).local().format('MMM Do, YYYY')}</span>
          </div>

          {task.messageCount > 0 && (
            <div className="pl-8">
              <FontAwesomeIcon icon={['fad', 'messages']} className="text-gray-400 mr-2" />
              <span className="text-gray-500">{task.messageCount}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};


export default TaskCard;