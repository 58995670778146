import classNames from 'classnames';

const ColorPicker = ({ color, setColor, disabled = false }) => {
  const availableColors = [
    { name: 'Red', hex: '#ef4444' },
    { name: 'Orange', hex: '#f97316' },
    { name: 'Amber', hex: '#f59e0b' },
    { name: 'Yellow', hex: '#eab308' },
    { name: 'Lime', hex: '#84cc16' },
    { name: 'Green', hex: '#22c55e' },
    { name: 'Emerald', hex: '#10b981' },
    { name: 'Teal', hex: '#14b8a6' },
    { name: 'Cyan', hex: '#06b6d4' },
    { name: 'Sky', hex: '#0ea5e9' },
    { name: 'Blue', hex: '#3b82f6' },
    { name: 'Indigo', hex: '#6366f1' },
    { name: 'Violet', hex: '#8b5cf6' },
    { name: 'Purple', hex: '#a855f7' },
    { name: 'Fuschia', hex: '#d946ef' },
    { name: 'Pink', hex: '#ec4899' },
    { name: 'Rose', hex: '#f43f5e' },
  ];

  const selectedColor = () => {
    return availableColors.find((availableColor) => {
      return (availableColor.hex === color);
    });
  };

  return (
    <>
      {/*
      {selectedColor() && (
        <div>
          <span className="text-gray-400">Selected Color: </span> <span className="font-bold" style={{ color : selectedColor().hex }}>{selectedColor().name}</span>
        </div>
      )}
      */}

      <div className="text-center">
        {availableColors.map((availableColor, index) => (
          <div key={index} className="inline-block m-2">
            <button type="button" disabled={disabled} onClick={() => setColor(availableColor.hex)} className={classNames('inline-block w-8 h-8 bg-black rounded-full ', { 'outline outline-2 outline-offset-2' : color === availableColor.hex })} title={availableColor.name} style={{ background: availableColor.hex, outlineColor : availableColor.hex }} />
          </div>
        ))}
      </div>
    </>
  );
};


export default ColorPicker;