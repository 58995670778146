import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import semver from 'semver';

import buildVersion from '../../src/version';

import Modal from 'components/modal';

const Update = (props) => {
  const [showModal, setShowModal] = useState(false);

  const checkForUpdate = async () => {
    const response = await axios.get(`/version.json?t=${Date.now()}`);

    if (response) {
      const buildVersionSemantic = `${buildVersion.buildMajor}.${buildVersion.buildMinor}.${buildVersion.buildRevision}`;
      const remoteVersionSemantic = `${response.data.buildMajor}.${response.data.buildMinor}.${response.data.buildRevision}`;

      if (semver.gt(remoteVersionSemantic, buildVersionSemantic)) {
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      checkForUpdate();
    }, 1000 * 60);

    return () => clearInterval(intervalHandle);
  }, []);

  return (
    <Modal show={showModal} setShow={setShowModal}>
      <div className="p-4">
        <div className="flex items-center">
          <div className="flex-grow">
            <span className="text-xl"><FontAwesomeIcon icon="fa-regular fa-rotate" className="text-gray-500 mr-2" />Update App</span>
          </div>

          <div>
            <button type="button" onClick={() => setShowModal(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
              <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
            </button>
          </div>
        </div>
      </div>

      <div className="pl-4 pb-12 pt-8 pr-4 text-center">
        <div className="text-xl">
          A new version of the OfficeGab app is available.
        </div>
        <div className="text-gray-500">
          Click the reload app button below to update to the new version.
        </div>

        <div className="mt-4">
          <button type="button" className="px-8 py-2 bg-primary text-white rounded" onClick={() => { window.location.reload(true); }}>Reload App</button>
        </div>
      </div>
    </Modal>
  );
};

export default Update;