import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone, { useDropzone } from 'react-dropzone';
import PasswordStrengthBar from 'react-password-strength-bar';
import classNames from 'classnames';
import moment from 'moment';
import toast from 'react-hot-toast';
import config from 'config';

import { formatBytes } from 'helpers/files';

import { useAuth } from 'hooks/useAuth';
import { useData } from 'hooks/useData';
import { useApi } from 'hooks/useApi';

import ColorPicker from 'components/color-picker';
import UserCard from 'components/user-card';
import Modal from 'components/modal';


const NewUser = ({ show, setShow }) => {
  const api = useApi();

  const [firstName, setFirstName]       = useState();
  const [lastName, setLastName]         = useState();
  const [email, setEmail]               = useState();
  const [title, setTitle]               = useState();
  const [birthDate, setBirthDate]       = useState();


  const resetInputs = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setTitle('');
    setBirthDate('');
  };


  const saveUser = (e) => {
    e.preventDefault();

    const createUserPayload = {
      firstName,
      lastName,
      email,
      title,
      birthDate: (birthDate) ? moment(birthDate).toISOString() : null
    };

    api.post(`user`, createUserPayload).then((response) => {
      toast(<><FontAwesomeIcon icon={['far', 'check']} className="inline-block align-middle text-green-500 mr-2" /><span className="inline-block align-middle">User has been successfully created.</span></>);

      resetInputs();
      setShow(false);
    }).catch((error) => {
      toast(<><FontAwesomeIcon icon={['far', 'warning']} className="inline-block align-middle text-red-500 mr-2" /><span className="inline-block align-middle">{error.response.data.message}</span></>);
    });
  };


  return (
    <Modal show={show ? true : false} setShow={setShow}>
      <div className="p-4">
        <div className="flex items-center gap-4">
          <div>
            <FontAwesomeIcon className="text-gray-500" icon={['fal', 'id-card-clip']} size="2x" />
          </div>

          <div className="flex-1 ">
            <span className="text-xl leading-none">New Staff Member</span>
          </div>

          <div>
            <button type="button" onClick={() => setShow(false)} className="inline-block px-2 text-gray-700 hover:text-primary">
              <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
            </button>
          </div>
        </div>
      </div>

      <div>
        <form onSubmit={saveUser}>
          <div className="space-y-3 p-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label>First Name</label>
                <input type="text" className="form-control mt-2" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
              </div>

              <div>
                <label>Last Name</label>
                <input type="text" className="form-control mt-2" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
              </div>
            </div>

            <div>
              <label>Email Address</label>
              <input type="email" className="form-control mt-2" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>

            <div>
              <label>Title \ Position</label>
              <input type="text" className="form-control mt-2" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>

            <div>
              <label>Birth Date</label>
              <input type="date" className="form-control mt-2 max-w-[200px]" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
            </div>
          </div>
          
          <div className="p-6 text-right">
            <button type="button" className="inline-block mx-6 text-gray-600 hover:text-gray-800" onClick={() => setShow(false)}>Cancel</button>
            <button type="submit" className="px-6 py-2 text-white bg-primary rounded font-bold outline-primary outline-offset-2 outline-2 hover:outline">Save Changes</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};


const EditUser = ({ user, setUser }) => {
  const api = useApi();
  const auth = useAuth();

  const [tab, setTab] = useState('profile');

  const [id, setId]                     = useState();
  const [firstName, setFirstName]       = useState();
  const [lastName, setLastName]         = useState();
  const [email, setEmail]               = useState();
  const [title, setTitle]               = useState();
  const [birthDate, setBirthDate]       = useState();
  const [avatarType, setAvatarType]     = useState();
  const [avatarColor, setAvatarColor]   = useState();

  const [userPhoto, setUserPhoto]       = useState();
  const [userPhotoUpload, setUserPhotoUpload] = useState(null);

  const [newPassword, setNewPassword]   = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const [userRole, setUserRole]         = useState();
  const [userDisabled, setUserDisabled] = useState();

  const onDrop = (acceptedFiles) => {
    acceptedFiles[0].preview = URL.createObjectURL(acceptedFiles[0]);
    setUserPhotoUpload(acceptedFiles[0]);
  };


  const changePassword = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      toast(<><FontAwesomeIcon icon={['far', 'warning']} className="inline-block align-middle text-red-500 mr-2" /><span className="inline-block align-middle">Confirm new password does not match new password</span></>);
      return;
    }

    const changePasswordPayload = {
      newPassword,
      confirmNewPassword
    };

    api.put(`user/${user.id}/changePassword`, changePasswordPayload).then((response) => {
      toast(<><FontAwesomeIcon icon={['far', 'check']} className="inline-block align-middle text-green-500 mr-2" /><span className="inline-block align-middle">User password has been changed</span></>);

      setNewPassword('');
      setConfirmNewPassword('');
    });
  };


  const loginAsUser = (userId) => {
    auth.loginAsUser(userId).then((response) => {
      window.location.reload(true);
    });
  };


  const saveUser = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (avatarType.toUpperCase() === 'UPLOAD' && userPhotoUpload) {
      formData.append('photo', userPhotoUpload, userPhotoUpload.name);
    }

    const updatedUser = {
      firstName,
      lastName,
      email,
      title,
      birthDate : birthDate ? moment(birthDate).toISOString() : null,
      avatarType,
      avatarColor
    };

    formData.append('user', JSON.stringify(updatedUser));

    api.put(`user/${user.id}`, formData).then((response) => {
      toast(<><FontAwesomeIcon icon={['far', 'check']} className="inline-block align-middle text-green-500 mr-2" /><span className="inline-block align-middle">Changes have been saved</span></>);
    });
  };


  const saveAdmin = (e) => {
    e.preventDefault();

    const formData = new FormData();

    const updatedUser = {
      role: userRole,
      disabled: userDisabled
    };

    formData.append('user', JSON.stringify(updatedUser));

    api.put(`user/${user.id}`, formData).then((response) => {
      toast(<><FontAwesomeIcon icon={['far', 'check']} className="inline-block align-middle text-green-500 mr-2" /><span className="inline-block align-middle">Changes have been saved</span></>);
    });
  }


  useEffect(() => {
    setUserPhotoUpload(null);
  }, [avatarType]);

  useEffect(() => {
    if (user) {
      setTab('profile');
      setId(user.id || 0);
      setFirstName(user.firstName || null);
      setLastName(user.lastName || null);
      setEmail(user.email || null);
      setTitle(user.title || null);
      setBirthDate(user.birthDate ? moment(user.birthDate).format('YYYY-MM-DD') : null);
      setAvatarType(user.avatarType || null);
      setAvatarColor(user.avatarColor || null);
      setUserPhoto(user.photo || null);
      setUserPhotoUpload(null);
      setUserRole(user.role);
      setUserDisabled(user.disabled);
    }
  }, [user]);

  return (
    <Modal show={user ? true : false} setShow={setUser}>
      <div className="p-4">
        <div className="flex items-center gap-4">
          <div>
            <FontAwesomeIcon className="text-gray-500" icon={['fal', 'id-card-clip']} size="2x" />
          </div>

          <div className="flex-1 ">
            <span className="text-xl leading-none">Edit User</span>
          </div>

          <div>
            <button type="button" onClick={() => setUser(false)} className="inline-block px-2 text-gray-700 hover:text-primary">
              <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 grid grid-cols-3 py-3 px-6 gap-4 shadow-inner">
        <button type="button" className={classNames('py-2 text-center rounded block w-full text-gray-600 hover:text-gray-800', { 'rounded shadow bg-white text-primary hover:text-primary' : tab === 'profile' })} onClick={() => { setTab('profile'); }}>User Profile</button>
        <button type="button" className={classNames('py-2 text-center rounded block w-full text-gray-600 hover:text-gray-800', { 'rounded shadow bg-white text-primary hover:text-primary' : tab === 'changePassword' })} onClick={() => { setTab('changePassword'); }}>Change Password</button>
        <button type="button" className={classNames('py-2 text-center rounded block w-full text-gray-600 hover:text-gray-800', { 'rounded shadow bg-white text-primary hover:text-primary' : tab === 'administration' })} onClick={() => { setTab('administration'); }}>Administration</button>
      </div>

      <div>
        {(tab === 'profile') && (
          <form onSubmit={saveUser}>
            <div className="space-y-3 p-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>First Name</label>
                  <input type="text" className="form-control mt-2" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>

                <div>
                  <label>Last Name</label>
                  <input type="text" className="form-control mt-2" defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
              </div>

              <div>
                <label>Email Address</label>
                <input type="text" className="form-control mt-2" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div>
                <label>Title \ Position</label>
                <input type="text" className="form-control mt-2" defaultValue={title} onChange={(e) => setTitle(e.target.value)} />
              </div>

              <div>
                <label>Birth Date</label>
                <input type="date" className="form-control mt-2 max-w-[200px]" defaultValue={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
              </div>

              <div>
                <label>Profile Picture</label>
                
                <div className="mt-2 space-y-2">
                  <div>
                    <input type="radio" name="avatarType" id="avatarType-auto" value="AUTO" defaultChecked={(avatarType === 'AUTO')} onChange={(e) => setAvatarType(e.target.value)} /> <label htmlFor="avatarType-auto" className="ml-2">Automatically choose a background color</label>
                  </div>

                  <div>
                    <input type="radio" name="avatarType" id="avatarType-manual" value="MANUAL" defaultChecked={(avatarType === 'MANUAL')} onChange={(e) => setAvatarType(e.target.value)} /> <label htmlFor="avatarType-manual" className="ml-2">Manually select a background color</label>
                  </div>

                  <div>
                    <input type="radio" name="avatarType" id="avatarType-upload" value="UPLOAD" defaultChecked={(avatarType === 'UPLOAD')} onChange={(e) => setAvatarType(e.target.value)} /> <label htmlFor="avatarType-upload" className="ml-2">Upload a photo to use for a profile picture</label>
                  </div>
                </div>

                {(avatarType === 'MANUAL') && (
                  <div className="mt-2 p-2 border border-gray-300 rounded bg-white shadow">
                    <ColorPicker color={avatarColor} setColor={(color) => { setAvatarColor(color); }} />
                  </div>
                )}

                {(avatarType === 'UPLOAD') && (
                  <div className="mt-2">
                    <Dropzone onDrop={onDrop} accept={['image/png', 'image/jpg', 'image/jpeg']} multiple={false} maxFiles={1}>
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <section>
                            <div {...getRootProps()} className="p-4 border-2 border-dashed border-gray-200 rounded text-center">
                              <input {...getInputProps()} />

                              {!userPhotoUpload && (
                                <>
                                  {!userPhoto && (
                                    <div className="py-12">Drag and drop a user photo here, or click to select a photo from your device.</div>
                                  )}

                                  {userPhoto && (
                                    <div>
                                      <div>
                                        <img src={config.apiUrl + 'file/' + userPhoto.accountId + '/' + userPhoto.name} className="max-w-full inline-block" />
                                      </div>
    
                                      <div className="mt-2">
                                        <div className="text-gray-700">{userPhoto.originalName}</div>
                                        <div className="text-sm text-gray-500">{formatBytes(userPhoto.size)}</div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {userPhotoUpload && (
                                <div>
                                  <div>
                                    <img src={userPhotoUpload.preview} onLoad={() => { URL.revokeObjectURL(userPhotoUpload.preview); }} className="max-w-full" />
                                  </div>

                                  <div className="mt-2">
                                    <div className="text-gray-700">{userPhotoUpload.name}</div>
                                    <div className="text-sm text-gray-500">{formatBytes(userPhotoUpload.size)}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        </>
                      )}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
            
            <div className="p-6">
              <div className="flex items-center">
                <div className="flex-1">
                    
                </div>

                <div>
                  <button type="button" className="inline-block mx-6 text-gray-600 hover:text-gray-800" onClick={() => setUser(false)}>Cancel</button>
                </div>

                <div>
                  <button type="submit" className="px-6 py-2 text-white bg-primary rounded font-bold outline-primary outline-offset-2 outline-2 hover:outline">Save Changes</button>
                </div>
              </div>
            </div>
          </form>
        )}


        {(tab === 'changePassword') && (
          <form onSubmit={changePassword}>
            <div className="space-y-3 p-6">
              <div className="max-w-[300px] mx-auto">
                <label>New Password</label>
                <input type="password" className="form-control mt-2" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                <div className="mt-2">
                  <PasswordStrengthBar password={newPassword} />
                </div>
              </div>

              <div className="max-w-[300px] mx-auto">
                <label>Confirm New Password</label>
                <input type="password" className="form-control mt-2 max-w-[300px]" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
              </div>
            </div>

            <div className="p-6 text-right">
              <button type="button" className="inline-block mx-6 text-gray-600 hover:text-gray-800" onClick={() => setUser(false)}>Cancel</button>
              <button type="submit" className="px-6 py-2 text-white bg-primary rounded font-bold outline-primary outline-offset-2 outline-2 hover:outline">Change Password</button>
            </div>
          </form>
        )}


        {(tab === 'administration') && (
          <form onSubmit={saveAdmin}>
            <div className="space-y-3 p-6">
              <div className="max-w-[400px] mx-auto">
                <label>User Role</label>
                <div className="border border-gray-300 rounded divide-y divide-gray-300 overflow-hidden mt-2">
                  <label className="flex gap-4 items-start px-4 py-2 cursor-pointer rounded-t hover:bg-gray-50" htmlFor="radioRoleUser">
                    <div>
                      <input type="radio" name="role" id="radioRoleUser" value="USER" checked={String(userRole).toUpperCase() === 'USER'} onChange={(e) => setUserRole(e.target.value)} />
                    </div>
                    <div className="flex-1">
                      <div className="font-bold">User</div>
                      <div>Basic application user. Can post gabs, create and complete tasks.</div>
                    </div>
                  </label>

                  <label className="flex gap-4 items-start px-4 py-2 cursor-pointer rounded-b hover:bg-gray-50" htmlFor="radioRoleAdmin">
                    <div>
                      <input type="radio" name="role" id="radioRoleAdmin" value="ADMIN" checked={String(userRole).toUpperCase() === 'ADMIN'} onChange={(e) => setUserRole(e.target.value)} />
                    </div>
                    <div className="flex-1">
                      <div className="font-bold">Administrator</div>
                      <div>Administrative level user. Can perform all actions as user in addition to staff management.</div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="max-w-[400px] mx-auto">
                <label>Disable User</label>
                <div className="border border-gray-300 rounded divide-y divide-gray-300 overflow-hidden mt-2">
                  <label className="flex gap-4 items-start px-4 py-2 cursor-pointer rounded-t hover:bg-gray-50" htmlFor="checkboxUserDisable">
                    <div>
                      <input type="checkbox" name="disable" id="checkboxUserDisable" value="1" checked={userDisabled} onChange={(e) => setUserDisabled(!userDisabled)} />
                    </div>
                    <div className="flex-1">
                      <div className="font-bold">Disable</div>
                      <div>Disable this users account so they can no longer sign in and use the application.</div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="max-w-[400px] mx-auto">
                <label>More Options</label>
                <div className="border border-gray-300 rounded divide-y divide-gray-300 overflow-hidden mt-2">
                  <div className="px-4 py-2">
                    <a href="#" className="hover:text-primary hover:underline" onClick={(e) => { e.preventDefault(); loginAsUser(id); }}>Sign in as user</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 text-right">
              <button type="button" className="inline-block mx-6 text-gray-600 hover:text-gray-800" onClick={() => setUser(false)}>Cancel</button>
              <button type="submit" className="px-6 py-2 text-white bg-primary rounded font-bold outline-primary outline-offset-2 outline-2 hover:outline">Save Changes</button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}


const ViewStaff = () => {
  const data = useData();
  const auth = useAuth();

  const [selectedUser, setSelectedUser]   = useState();
  const [showNewUser, setShowNewUser]     = useState();
  const [search, setSearch]               = useState();

  const filteredUsers = () => {
    if (search) {
      return data.users.filter((user) => {
        if (user.firstName && user.firstName.toLowerCase().search(search.toLowerCase()) >= 0) return true;
        if (user.lastName && user.lastName.toLowerCase().search(search.toLowerCase()) >= 0) return true;
        if (user.email && user.email.toLowerCase().search(search.toLowerCase()) >= 0) return true;
        if (user.title && user.title.toLowerCase().search(search.toLowerCase()) >= 0) return true;
        return false;
      });
    }
    
    return data.users;
  };

  const enabledUsersByRole = (role) => {
    return filteredUsers().filter((user) => {
      return (user.disabled === false && user.role.toLowerCase() === role.toLowerCase());
    });
  };

  const disabledUsers = () => {
    return filteredUsers().filter((user) => {
      return user.disabled;
    });
  };

  return (
    <>
      <div className="h-full">
        <div className="p-10">
          <div className="flex items-center gap-x-4">
            <div className="flex-1">
              <h1 className="text-2xl">{auth.user?.account?.name} Staff</h1>
            </div>

            <div>
              <div className="flex items-center border border-gray-300 rounded overflow-hidden bg-white shadow-sm">
                <div className="px-2">
                  {!search && (
                    <FontAwesomeIcon icon={['fas', 'search']} fixedWidth className="text-gray-500" />
                  )}

                  {search && (
                    <button type="button" onClick={(e) => setSearch('')}>
                      <FontAwesomeIcon icon={['fas', 'times']} fixedWidth className="text-gray-500" />
                    </button>
                  )}
                </div>
                <div className="flex-1">
                  <input type="text" className="border-0 rounded-r outline-0 ring-0 focus:outline-0 focus:ring-0 pl-0" placeholder="Search staff" value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
              </div>
            </div>

            <div>
              <button type="button" className="btn bg-green-500 text-white px-4 py-2 rounded font-semibold hover:bg-green-400" onClick={() => setShowNewUser(true)}><FontAwesomeIcon icon={['far', 'user-plus']} className="mr-2" />New Staff Member</button>
            </div>
          </div>

          {enabledUsersByRole('admin').length > 0 && (
            <>
              <h2 className="text-xl mt-4 border-b border-gray-200 pb-4 text-primary">Administrators</h2>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {enabledUsersByRole('admin').map((user) => (
                  <UserCard user={user} key={user.id} onClick={() => { setSelectedUser(user); }} />
                ))}
              </div>
            </>
          )}

          {enabledUsersByRole('user').length > 0 && (
            <>
              <h2 className="text-xl mt-4 border-b border-gray-200 pb-4 text-primary">Users</h2>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {enabledUsersByRole('user').map((user) => (
                  <UserCard user={user} key={user.id} onClick={() => { setSelectedUser(user); }} />
                ))}
              </div>
            </>
          )}
          
          {disabledUsers().length > 0 && (
            <>
              <h2 className="text-xl mt-4 border-b border-gray-200 pb-4 text-primary">Disabled Users</h2>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {disabledUsers().map((user) => (
                  <UserCard user={user} key={user.id} onClick={() => { setSelectedUser(user); }} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <EditUser user={selectedUser} setUser={setSelectedUser} />
      <NewUser show={showNewUser} setShow={setShowNewUser} />
    </>
  );
};


export default ViewStaff;