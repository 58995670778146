import { useContext, createContext, useState, useEffect } from 'react';

import { useApi } from 'hooks/useApi';
import { useSocket } from 'hooks/useSocket';
import { useAuth } from 'hooks/useAuth';
import _ from 'lodash';

import parse from 'html-react-parser';
import toast from 'react-hot-toast';
import moment from 'moment';

import notificationSound from 'mp3/notification2.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Howl, Howler } from 'howler';


const dataContext = createContext();

export function DataProvider({ children }) {
  const data = useDataProvider();

  return <dataContext.Provider value={data}>{children}</dataContext.Provider>
};

export const useData = () => {
  return useContext(dataContext);
};

function useDataProvider() {
  const api = useApi();
  const socket = useSocket();
  const auth = useAuth();

  const [users, setUsers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskPriorities, setTaskPriorities] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [userUnreadMessages, setUserUnreadMessages] = useState([]);
  const [userUnreadTasks, setUserUnreadTasks] = useState([]);


  const loadUsers = () => {
    return new Promise((resolve, reject) => {
      api.get('user').then((response) => {
        const users = response.data;
        setUsers(users);

        resolve(users);
      }).catch((error) => {
        reject(error);
      });
    });
  };

  const updateUser = (user, id) => {
    return new Promise((resolve, reject) => {
      api.put(`user/${id}`, user).then((response) => {
        socket.socket.emit('userUpdated', response.data);

        resolve(response.data);
      });
    });
  };

  const loadUserUnreadTasks = () => {
    return new Promise((resolve, reject) => {
      api.get('task/userUnreadTasks').then((response) => {
        const userUnreadTasks = response.data;
        setUserUnreadTasks(userUnreadTasks);
        resolve(userUnreadTasks);
      });
    });
  };

  const deleteUserUnreadTasks = (taskId) => {
    return new Promise((resolve, reject) => {
      api.delete(`task/userUnreadTasks/${taskId}`).then((response) => {
        const updatedUserUnreadTask = response.data;
        resolve(updatedUserUnreadTask);
      });
    });
  };

  const loadUserUnreadMessages = () => {
    return new Promise((resolve, reject) => {
      api.get(`message/userUnreadMessages`).then((response) => {
        const userUnreadMessages = response.data;
        setUserUnreadMessages(userUnreadMessages);
        resolve(userUnreadMessages);
      });
    });
  };

  const clearUserUnreadMessages = (moduleName, moduleId) => {
    return new Promise((resolve, reject) => {
      api.delete(`message/${moduleName}/${moduleId}/userUnreadMessages`).then((response) => {
        resolve(response);
      });
    });
  };

  const loadChannels = () => {
    return new Promise((resolve, reject) => {
      api.get('channel').then((response) => {
        const channels = response.data;
        setChannels(channels);

        resolve(channels);
      }).catch((error) => {
        reject(error);
      })
    });
  };

  const updateChannel = (id, channel) => {
    return new Promise((resolve, reject) => {
      api.put(`channel/${id}`, channel).then((response) => {
        resolve(response.data);
      });
    });
  };

  const createChannel = (channel) => {
    return new Promise((resolve, reject) => {
      api.post(`channel`, channel).then((response) => {
        resolve(response.data);
      });
    });
  };

  const loadTasks = () => {
    return new Promise((resolve, reject) => {
      api.get('task').then((response) => {
        const tasks = response.data;
        setTasks(tasks);

        resolve(tasks);
      }).catch((error) => {
        reject(error);
      })
    });
  };

  const loadTask = (taskId) => {
    return new Promise((resolve, reject) => {
      api.get(`task/${taskId}`).then((response) => {
        const task = response.data;

        const existingTaskIndex = tasks.findIndex((taskToFind) => {
          return (taskToFind.id == task.id);
        });
    
        if (existingTaskIndex >= 0) {
          const updatedTask = { ...tasks[existingTaskIndex], ...task };
    
          const updatedTasks = [...tasks];
    
          updatedTasks[existingTaskIndex] = updatedTask;
    
          setTasks(updatedTasks);
        }

        resolve(task);
      });
    });
  };

  const loadMessagesByModuleNameModuleId = (moduleName, moduleId, dateRange = null) => {
    if (dateRange === null) {
      dateRange = {
        startDate: moment().subtract(1, 'year').toISOString(),
        endDate: moment().toISOString()
      }
    }

    return new Promise((resolve, reject) => {
      const request = {
        dateRange
      };

      api.get(`message/${moduleName}/${moduleId}`, request).then((response) => {
        const messages = response.data;

        setMessages(messages);

        resolve(messages);
      }).catch((error) => {
        reject(error);
      })
    });
  };

  const loadMessageByMessageId = (messageId) => {
    return new Promise((resolve, reject) => {
      api.get(`message/${messageId}`).then((response) => {
        const message = response.data;

        resolve(message);
      });
    });
  };

  const loadTaskPriorities = () => {
    return new Promise((resolve, reject) => {
      api.get('task/priorities').then((response) => {
        const priorities = response.data;

        setTaskPriorities(priorities);

        resolve(priorities);
      }).catch((error) => {
        reject(error);
      });
    });
  };


  //Users
  socket.socket?.off('userUpdated').on('userUpdated', (user) => {
    const existingUserIndex = users.findIndex((userToFind) => {
      return (userToFind.id == user.id);
    });

    if (existingUserIndex >= 0) {
      const updatedUsers = [...users];
      updatedUsers[existingUserIndex] = user;
      setUsers(updatedUsers);

      if (user.id == auth.user.id) {
        auth.loadUser();
      }
    }
  });


  socket.socket?.off('newUser').on('newUser', (user) => {
    setUsers([...users, user]);
  });


  //Tasks
  socket.socket?.off('newTask').on('newTask', (task) => {
    setTasks([...tasks, task]);

    const taskUserIds = task.taskUsers.map((taskUser) => taskUser.userId);

    if (taskUserIds.indexOf(auth.user.id) >= 0) {
      const howler = new Howl({
        src: notificationSound
      });

      howler.play();

      toast(
        (t) => (
          <div className="flex gap-4">
            <div>
              <div>
                New task from <strong>{task.user?.firstName} {task.user?.lastName}</strong>
              </div>

              <div className="mt-2 text-sm">
                {parse(task.title)}
              </div>
            </div>
            <div>
              <button type="button" className="" onClick={() => toast.dismiss(t.id)}>
                <FontAwesomeIcon icon={['far', 'times']} />
              </button>
            </div>
          </div>
        ),
        {
          duration: 5000
        }
      );
    }
  });

  socket.socket?.off('taskUpdated').on('taskUpdated', (task) => {
    const existingTaskIndex = tasks.findIndex((taskToFind) => {
      return (taskToFind.id == task.id);
    });

    if (existingTaskIndex >= 0) {
      const updatedTask = { ...tasks[existingTaskIndex], ...task };

      const updatedTasks = [...tasks];

      updatedTasks[existingTaskIndex] = updatedTask;

      setTasks(updatedTasks);
    }
  });

  socket.socket?.off('taskDeleted').on('taskDeleted', (task) => {
    const taskIndex = tasks.findIndex((findTask) => {
      return (findTask.id == task.id);
    });

    if (taskIndex >= 0) {
      const newTasks = [...tasks];

      newTasks.splice(taskIndex, 1);

      setTasks(newTasks);
    }
  });


  socket.socket?.off('userUnreadTasksUpdate').on('userUnreadTasksUpdate', (updatedUserUnreadTasks) => {
    const userUnreadTasksCopy = _.cloneDeep(userUnreadTasks);
    
    updatedUserUnreadTasks.forEach((updatedUserUnreadTask) => {
      const existingUserUnreadTaskIndex = userUnreadTasks.findIndex((userUnreadTask) => userUnreadTask.id == updatedUserUnreadTask.id);

      if (existingUserUnreadTaskIndex >= 0) {
        userUnreadTasksCopy[existingUserUnreadTaskIndex] = updatedUserUnreadTask;
      }
    });

    setUserUnreadTasks(userUnreadTasksCopy);
  });


  socket.socket?.off('userUnreadTasksDeleted').on('userUnreadTasksDeleted', (deletedUserUnreadTasks) => {
    setUserUnreadTasks(userUnreadTasks.filter((userUnreadTask) => {
      return (deletedUserUnreadTasks.find((deletedUserUnreadTask) => deletedUserUnreadTask.id == userUnreadTask.id) === undefined);
    }));
  });


  socket.socket?.off('userUnreadTasksCreated').on('userUnreadTasksCreated', (createdUserUnreadTasks) => {
    setUserUnreadTasks(userUnreadTasks.concat(createdUserUnreadTasks.filter(createdUserUnreadTask => createdUserUnreadTask.userId == auth.user.id)));
  });


  // Channels
  socket.socket?.off('newChannel').on('newChannel', (channel) => {
    setChannels([...channels, channel]);
  });


  socket.socket?.off('channelUpdated').on('channelUpdated', (channel) => {
    const channelIndex = channels.findIndex((findChannel) => {
      return (findChannel.id == channel.id);
    });

    if (channelIndex >= 0) {
      const newChannels = [...channels];

      newChannels[channelIndex] = channel;

      setChannels(newChannels);
    }
  });


  socket.socket?.off('channelDeleted').on('channelDeleted', (deletedChannel) => {
    setChannels(channels.filter(channel => channel.id != deletedChannel.id));
  });


  // Messages
  socket.socket?.off('newMessage').on('newMessage', (message) => {
    setMessages([...messages, message]);

    let matchingUser = null;    

    if (message.userId != auth.user.id) {
      if (message.moduleName.toLowerCase() == 'channel') {
        matchingUser = message.channel?.channelUsers?.find((channelUser) => {
          return (channelUser.userId == auth.user.id);
        });
      }
  
      if (message.moduleName.toLowerCase() == 'direct') {
        if (message.moduleId.split(':').map(userId => parseInt(userId)).includes(auth.user.id)) {
          matchingUser = true;
        }
      }
  
      if (message.moduleName.toLowerCase() == 'task') {
        matchingUser = message.task?.taskUsers?.find((taskUser) => {
          return (taskUser.userId == auth.user.id);
        });

        if (!matchingUser) {
          if (message.task.userId == auth.user.id) {
            matchingUser = auth.user;
          }
        }
      }

      if (matchingUser) {
        const howler = new Howl({
          src: notificationSound
        });
  
        howler.play();
  
        toast(
          (t) => (
            <div className="flex gap-4">
              <div>
                <div>
                  {(message.moduleName.toLowerCase() === 'channel') && (
                    <>
                      <strong>{message.user?.firstName} {message.user?.lastName}</strong> posted in <strong>{message.channel?.title}</strong>
                    </>
                  )}
  
                  {(message.moduleName.toLowerCase() === 'direct') && (
                    <>
                      Direct gab from <strong>{message.user?.firstName} {message.user?.lastName}</strong>
                    </>
                  )}

                  {(message.moduleName.toLowerCase() === 'task') && (
                    <>
                      <strong>{message.user?.firstName} {message.user?.lastName}</strong> posted a message to <strong>{message.task?.title}</strong>
                    </>
                  )}
                </div>
  
                {(message.type.toLowerCase() === 'message') && (
                  <div className="mt-2 text-sm">
                    {parse(message.message)}
                  </div>
                )}
              </div>
              <div>
                <button type="button" className="" onClick={() => toast.dismiss(t.id)}>
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
            </div>
          ),
          {
            duration: 5000
          }
        );
      }
    }
  });


  socket.socket?.off('messageUpdated').on('messageUpdated', (message) => {
    const matchedMessageIndex = messages.findIndex((findMessage) => {
      return (findMessage.id == message.id);
    })

    if (matchedMessageIndex >= 0) {
      const newMessages = [...messages];

      newMessages[matchedMessageIndex] = message;

      setMessages(newMessages);
    }
  });


  socket.socket?.off('messageDeleted').on('messageDeleted', (message) => {
    const matchedMessageIndex = messages.findIndex((findMessage) => {
      return (findMessage.id == message.id);
    })

    if (matchedMessageIndex >= 0) {
      const newMessages = [...messages];

      newMessages.splice(matchedMessageIndex, 1);

      setMessages(newMessages);
    }
  });


  socket.socket?.off('newUserUnreadMessages').on('newUserUnreadMessages', (newUserUnreadMessages) => {
    setUserUnreadMessages(userUnreadMessages.concat(newUserUnreadMessages.filter(newUserUnreadMessage => newUserUnreadMessage.userId == auth.user.id)));
  });


  socket.socket?.off('userUnreadMessagesDeleted').on('userUnreadMessagesDeleted', (deletedUserUnreadMessages) => {
    setUserUnreadMessages(userUnreadMessages.filter(userUnreadMessage => {
      const deletedUserUnreadMessageMatch = deletedUserUnreadMessages.find(deletedUserUnreadMessage => deletedUserUnreadMessage.id == userUnreadMessage.id);

      return (deletedUserUnreadMessageMatch === undefined);
    }));
  });


  return {
    users,
    userUnreadMessages,
    userUnreadTasks,
    channels,
    messages,
    tasks,
    taskPriorities,
    setUsers,
    setChannels,
    setMessages,
    setTasks,
    setTaskPriorities,
    loadUsers,
    loadUserUnreadTasks,
    loadUserUnreadMessages,
    deleteUserUnreadTasks,
    updateUser,
    clearUserUnreadMessages,
    loadChannels,
    createChannel,
    updateChannel,
    loadTasks,
    loadTask,
    loadMessagesByModuleNameModuleId,
    loadMessageByMessageId,
    loadTaskPriorities
  };
};