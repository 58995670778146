import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-jdenticon-sprites';

const ChannelIcon = (props) => {
    const svg = createAvatar(style, {
        seed        : props.name,
        dataUri     : true,
        fontSize    : 40,
        bold        : true
    });

    return <img className={props.className} src={svg} />
};


export default ChannelIcon;