import { useContext, createContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import config from 'config';

const socketContext = createContext();

export function SocketProvider({ children })
{
    const socket = useSocketProvider();

    return <socketContext.Provider value={socket}>{children}</socketContext.Provider>
};

export const useSocket = () => {
    return useContext(socketContext);
};

function useSocketProvider()
{
    const [socket, setSocket]           = useState();
    const [connected, setConnected]     = useState(false);

    useEffect(() => {
        const socketClient = io(config.socketUrl, { rejectUnauthorized: false, secure: false });

        socketClient.on('error', (error) => {
            console.log(error);
        });

        socketClient.on('connect', () => {
            setConnected(true);
        });

        socketClient.on('disconnect', () => {
            setConnected(false);
        });

        setSocket(socketClient);
    }, []);

    return {
        socket,
        connected
    };
};