import { useContext, createContext } from "react";
import axios from "axios";
import config from "config";
import qs from 'qs';

const apiContext = createContext();

export function ApiProvider({ children }) {
    const api = useApiProvider();

    return <apiContext.Provider value={api}>{children}</apiContext.Provider>;
}

export const useApi = () => {
    return useContext(apiContext);
};

function useApiProvider() {
    const craftHeaders = (additionalHeaders = {}) => {
        let defaultHeaders = {};

        const token = JSON.parse(localStorage.getItem('token'));

        if (token)
        {
            defaultHeaders['Authorization'] = `Bearer ${token}`;
        }

        return {...defaultHeaders, ...additionalHeaders};
    };

    const get = (endpoint, params = {}) => {
        return axios({
            method              : 'get',
            url                 : config.apiUrl + endpoint,
            paramsSerializer    : (params) => qs.stringify(params, { encodeValuesOnly : true }),
            params              : params,
            headers             : craftHeaders(),
            timeout             : 10000
        });
    };

    const post = (endpoint, data = {}) => {
        return axios({
            method      : 'post',
            url         : config.apiUrl + endpoint,
            data        : data,
            headers     : craftHeaders(),
            timeout     : 10000
        });
    };

    const put = (endpoint, data = {}) => {
        return axios({
            method      : 'put',
            url         : config.apiUrl + endpoint,
            data        : data,
            headers     : craftHeaders(),
            timeout     : 10000
        });
    };

    const del = (endpoint, data = {}) => {
        return axios({
            method      : 'delete',
            url         : config.apiUrl + endpoint,
            data        : data,
            headers     : craftHeaders(),
            timeout     : 10000
        });
    };

    return {
        get,
        post,
        put,
        delete: del,
    };
}
