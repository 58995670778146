import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import { useData } from 'hooks/useData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';

const PrioritySelect = ({ value, setValue, style = 'inline' }) => {
    const data = useData();


    const selectedPriority = () => {
        return data.taskPriorities.find((taskPriority) => {
            return (taskPriority.value == value);
        });
    };


    if (style == 'dropdown')
    {
        return (
            <Listbox value={value} onChange={setValue}>
                {({ open }) => (
                    <Fragment>
                        <div className="relative">
                            <Listbox.Button className="bg-gray-50 relative w-full rounded pl-3 pr-10 py-2 text-left">
                                <div className="flex items-center gap-3">
                                    <div>
                                        <FontAwesomeIcon icon={['fas', 'square']} style={{ color : selectedPriority()?.color }} />
                                    </div>

                                    <div>
                                        <span className="block truncated">{selectedPriority()?.label}</span>
                                    </div>
                                </div>
                                
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <FontAwesomeIcon icon={['fas', 'caret-down']} className="text-gray-500" />
                                </span>
                            </Listbox.Button>

                            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto">
                                    {data.taskPriorities.map((priority) => (
                                        <Listbox.Option key={priority.id} className={({ active }) => classNames(active ? "bg-gray-100" : "text-gray-900", "relative py-2 pl-3 pr-9 cursor-pointer")} value={priority.value}>
                                            {({ selected, active }) => (
                                                <div className="flex items-center gap-3">
                                                    <div>
                                                        <FontAwesomeIcon icon={['fas', 'square']} style={{ color : priority.color }} />
                                                    </div>

                                                    <div>
                                                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>{priority.label}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Fragment>
                )}
            </Listbox>
        );
    }


    return (
        <div className="grid grid-cols-5 gap-1.5">
            {data.taskPriorities.map((priority) => (
                <button type="button" className={classNames('text-white font-bold text-sm py-2 rounded', { 'outline outline-2 outline-offset-2' : value == priority.value })} key={priority.id} style={{ background : priority.color, outlineColor : priority.color }} onClick={() => setValue(priority.value)}>
                    {priority.label}
                </button>
            ))}
        </div>
    );
};


export default PrioritySelect;