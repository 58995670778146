import { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApi } from 'hooks/useApi';

import Dropzone, { useDropzone } from 'react-dropzone';
import { FileIcon, defaultStyles } from 'react-file-icon';

import { getFileExtension, formatBytes } from 'helpers/files';

import Modal from 'components/modal';

const UploadFile = (props) => {
  const api = useApi();

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);


  const onDrop = (acceptedFiles) => {
    setAcceptedFiles(acceptedFiles);
  };


  const uploadFiles = () => {
    setUploading(true);

    const data = new FormData();

    acceptedFiles.forEach((acceptedFile, index) => {
      data.append(`file-${index}`, acceptedFile, acceptedFile.name);
    });

    data.append('data', JSON.stringify({
      moduleName: props.moduleName,
      moduleId: props.moduleId
    }));

    api.post('message', data).then((response) => {
      setUploading(false);

      props.setShow(false);
    });
  };


  const removeFile = (index) => {
    const newAcceptedFiles = [...acceptedFiles];

    newAcceptedFiles.splice(index, 1);

    setAcceptedFiles(newAcceptedFiles);
  };


  useEffect(() => {
    if (props.show) {
      setAcceptedFiles([]);
    }
  }, [props.show]);


  return (
    <Fragment>
      <Modal show={(props.show) ? true : false} setShow={props.setShow}>
        <div className="p-4">
          <div className="flex items-center">
            <div className="flex-grow">
              <FontAwesomeIcon icon={['far', 'paperclip']} size="lg" className="mr-3 text-gray-400" /><span className="text-xl">Attach Files</span>
            </div>

            <div>
              {!uploading && (
                <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                  <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="pl-4 pb-4 pr-4">
          <div>
            {(acceptedFiles.length <= 0) && (
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="px-4 py-12 border-2 border-dashed border-gray-200 rounded text-center">
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or click to select files from your computer.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}

            {(acceptedFiles.length > 0) && (
              <div className="mt-4 space-y-5">
                {acceptedFiles.map((file, index) => (
                  <div className="flex items-center" key={index}>
                    <div className="w-12">
                      <FileIcon extension={getFileExtension(file.name)} {...defaultStyles[getFileExtension(file.name)]} />
                    </div>

                    <div className="flex-grow pl-5">
                      <div className="text-lg font-bold">{file.name}</div>
                      <div>{formatBytes(file.size)}</div>
                    </div>

                    <div>
                      {!uploading && (
                        <button type="button" className="text-gray-500 hover:text-primary" onClick={() => removeFile(index)}>
                          <FontAwesomeIcon icon={['far', 'trash-alt']} />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex mt-2 items-center">
            <div className="flex-grow">
              {(acceptedFiles.length > 0 && !uploading) && (
                <button className="text-gray-500 hover:text-primary" onClick={() => setAcceptedFiles([])}>
                  Clear all chosen files
                </button>
              )}
            </div>

            <div className="mt-2">
              <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-white text-gray-700 border border-gray-200 mr-2" onClick={() => props.setShow(false)} disabled={uploading}>
                Cancel
              </button>

              <button type="button" className="px-4 h-8 rounded-md font-bold text-sm bg-primary text-white disabled:bg-gray-300" disabled={acceptedFiles.length == 0 || uploading} onClick={() => uploadFiles()}>
                {!uploading && (
                  <span>Send</span>
                )}

                {uploading && (
                  <span>
                    <FontAwesomeIcon icon={['fas', 'spinner']} spin className="mr-2" /> Uploading files...
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};


export default UploadFile;