import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Link,
  useParams,
  useHistory
} from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-hot-toast';

import { useApi } from 'hooks/useApi';

import Logo from 'img/logo-white-text.png';

const ViewResetPassword = () => {
  const [newPassword, setNewPassword]                             = useState(undefined);
  const [confirmNewPassword, setConfirmNewPassword]               = useState(undefined);
  const [newPasswordErrors, setNewPasswordErrors]                 = useState([]);
  const [confirmNewPasswordErrors, setConfirmNewPasswordErrors]   = useState([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const params = useParams();
  const api = useApi();

  const resetPassword = (e) => {
    e.preventDefault();

    const request = {
      newPassword: newPassword,
      forgotPasswordKey: params.forgotPasswordKey
    };

    setLoading(true);

    api.post('auth/resetPassword', request).then((response) => {
      toast(
        (t) => (
          <div className="flex gap-4">
            <div>
              <FontAwesomeIcon icon={['far', 'thumbs-up']} className="text-green-500" size="3x" />
            </div>
            <div>
              <div className="font-semibold">
                Password Reset
              </div>
              <div className="mt-1">Your password has been successfully reset! You may now sign in with your new password.</div>
            </div>
            <div>
              <button type="button" className="" onClick={() => toast.dismiss(t.id)}>
                <FontAwesomeIcon icon={['far', 'times']} />
              </button>
            </div>
          </div>
        ),
        {
          duration: 5000
        }
      );

      history.push('/login');
    }).catch((error) => {
      toast(
        (t) => (
          <div className="flex gap-4">
            <div>
              <FontAwesomeIcon icon={['far', 'warning']} className="text-primary" size="3x" />
            </div>
            <div>
              <div className="font-semibold">
                Unable to reset password
              </div>
              <div className="mt-1">{error.response.data.message}</div>
            </div>
            <div>
              <button type="button" className="" onClick={() => toast.dismiss(t.id)}>
                <FontAwesomeIcon icon={['far', 'times']} />
              </button>
            </div>
          </div>
        ),
        {
          duration: 5000
        }
      );
    }).finally(() => {
      setLoading(false);
    });
  };
  
  const runPasswordValidations = () => {
    if (newPassword !== undefined) {
      const updatedNewPasswordErrors = [];

      if (!newPassword) {
        updatedNewPasswordErrors.push('The new password field is required');
      }

      if (newPassword.length < 6) {
        updatedNewPasswordErrors.push('Your password must be at least 6 characters long');
      }

      setNewPasswordErrors(updatedNewPasswordErrors);
    }

    if (confirmNewPassword !== undefined) {
      const updatedConfirmNewPasswordErrors = [];

      if (!confirmNewPassword) {
        updatedConfirmNewPasswordErrors.push('The confirm new password field is required');
      }

      if (confirmNewPassword !== newPassword) {
        updatedConfirmNewPasswordErrors.push('Passwords do not match');
      }

      setConfirmNewPasswordErrors(updatedConfirmNewPasswordErrors);
    }
  };

  const isResetPasswordButtonDisabled = () => {
    if (
        (newPassword !== undefined && confirmNewPassword !== undefined)
        &&
        (newPasswordErrors.length == 0 && confirmNewPasswordErrors.length == 0)
      ) {
        return false;
      }

    return true;
  };

  useEffect(() => {
    runPasswordValidations();
  }, [newPassword, confirmNewPassword]);

  return (
    <>
      <div className="h-screen flex flex-col login-bg justify-center">
        <div className="mx-auto" style={{ width : '520px'}}>
          <div>
            <img src={Logo} className="max-w-xs mx-auto" />
          </div>

          <div className="bg-white p-14 rounded-md drop-shadow-lg mt-5">
            <form onSubmit={resetPassword}>
              <h1 className="text-xl font-semibold">Password Reset</h1>

              <div className="mt-5">
                Enter a new password to reset the password on your account. We'll ask for this password whenever you sign in.
              </div>

              <div className="mt-5">New Password <span className="text-primary">*</span></div>

              <div className="mt-2">
                  <input
                    type="password"
                    id="newPassword"
                    className={classNames('form-control mt-1 text-lg', { 'border-primary' : newPasswordErrors.length > 0 })}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onBlur={runPasswordValidations}
                    autoFocus
                  />

                  {(newPasswordErrors.length > 0) && (
                    <div className="mt-2 space-y-1">
                      {newPasswordErrors.map((newPasswordError, index) => (
                        <div className="flex gap-2" key={index}>
                          <div>
                            <FontAwesomeIcon icon={['far', 'warning']} className="text-primary" />
                          </div>
                          <div className="flex-1">{newPasswordError}</div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              <div className="mt-5">Confirm New Password <span className="text-primary">*</span></div>

              <div className="mt-2">
                  <input
                    type="password"
                    id="confirmNewPassword"
                    className={classNames('form-control mt-1 text-lg', { 'border-primary' : confirmNewPasswordErrors.length > 0 })}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    onBlur={runPasswordValidations}
                  />

                  {(confirmNewPasswordErrors.length > 0) && (
                    <div className="mt-2 space-y-1">
                      {confirmNewPasswordErrors.map((confirmNewPasswordError, index) => (
                        <div className="flex gap-2" key={index}>
                          <div>
                            <FontAwesomeIcon icon={['far', 'warning']} className="text-primary" />
                          </div>
                          <div className="flex-1">{confirmNewPasswordError}</div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              <div className="mt-10 text-center">
                <button type="submit" className="bg-primary text-white font-bold text-lg rounded-full px-10 py-2 disabled:bg-gray-100 disabled:text-gray-400 transition-all" disabled={isResetPasswordButtonDisabled()}>
                  {!loading && <span>Reset Password</span>}

                  {loading && <FontAwesomeIcon icon={['fad', 'spinner-third']} spin size="lg" />}
                </button>
              </div>

              <div className="mt-10 text-center">
                Know your password? <Link className="text-primary hover:underline" to="/login">Sign In.</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default ViewResetPassword;