import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { useApi } from 'hooks/useApi';

import Modal from 'components/modal';
import UserSelect from 'components/user-select';



const ChannelAddUsers = (props) => {
  const api = useApi();

  const [usersToAdd, setUsersToAdd] = useState([]);


  const addUsers = () => {
    const channel = { ...props.channel };

    const existingUsersIds = channel.channelUsers.map((channelUser) => channelUser.userId);

    const userIdsToAdd = usersToAdd.filter((userIdToAdd) => {
      return !existingUsersIds.includes(userIdToAdd);
    });

    if (userIdsToAdd.length) {
      const request = {
        userIds: userIdsToAdd
      };

      api.post(`channel/${props.channel.id}/addUsers`, request).then((response) => {
        const channel = response.data;

        if (props.usersAdded) {
          props.usersAdded();
        }

        props.setShow(false);
      });
    }
  };


  return (
    <Fragment>
      <Modal show={(props.show) ? true : false} setShow={props.setShow}>
        <div className="p-4">
          <div className="flex items-center">
            <div className="flex-grow">
              <span className="text-xl">
                <FontAwesomeIcon icon={['far', 'user-plus']} className="mr-2 text-primary" />Add Team Members
              </span>
            </div>

            <div>
              <button type="button" onClick={() => props.setShow(false)} className="inline-block py-1 px-2 text-gray-700 hover:text-primary">
                <FontAwesomeIcon icon={['fas', 'times']} size="lg" />
              </button>
            </div>
          </div>
        </div>

        <div className="pl-4 pb-4 pr-4">
          <UserSelect hiddenUsers={props.channel?.channelUsers?.map((channelUser) => channelUser.userId)} setUsers={setUsersToAdd} />

          <div className="mt-6 text-center">
            <button type="button" className="bg-primary text-white py-2 px-4 rounded font-bold disabled:bg-gray-300" disabled={usersToAdd.length == 0} onClick={() => addUsers()}>
              <FontAwesomeIcon icon={['far', 'check']} className="mr-2" /> Add Team Members
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};


export default ChannelAddUsers;