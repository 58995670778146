export function pair(a, b) {
    let x = a;
    let y = b;

    if (b < a)
    {
        x = b;
        y = a;
    }

    //return (x >= y ? (x * x) + x + y : (y * y) + x);

    return `${x}:${y}`;
}