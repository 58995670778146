import { useAuth } from 'hooks/useAuth';

import UserAvatar from "./user-avatar";


const UserCard = ({ user, onClick }) => {
  const auth = useAuth();

  return (
    <div className="p-4 rounded cursor-pointer hover:outline outline-gray-300 flex gap-4 items-center hover:bg-gray-50" onClick={onClick} key={user.id}>
      <div className="w-16 flex-shrink-0">
        <UserAvatar user={user} className="w-16 h-16 rounded border-2 border-white shadow" />
      </div>

      <div className="flex-1 overflow-hidden">
        <div className="font-bold truncate">
          {user.firstName} {user.lastName}
        </div>

        {user.title && (
          <div className="mt-0 text-sm text-gray-700 truncate">
            {user.title}
          </div>
        )}

        {user.email && (
          <div className="mt-0 text-sm text-gray-500 truncate">
            {user.email}
          </div>
        )}
      </div>
    </div>
  );
};


export default UserCard;